import { useCustomToast } from '@elkaso-app/web-design';
import { useGetAggSheetsRelationsApi } from 'apis/agg-sheets-relations/use-get-agg-sheets-relations-api';
import http from 'apis/config/http-service';
import { useMutationWrapper } from 'apis/config/use-api-wrapper';
import type { AxiosError } from 'axios';
import type { UseMutationOptions, UseMutationResult } from 'react-query';

const endpoint = 'oms/v1/aggregated-sheets';
export const editAggregatedSheetsRelationsEndpointIdentifier = endpoint;

type Recipients = {
  to: string[];
  cc?: string[];
  bcc?: string[];
};

interface BodyType {
  recipients?: Recipients;
  processingBufferTime?: number; // Minutes
  hasLastOrderRule?: boolean;
  templateName?: string;
}

type TData = Record<string, any>;
type TError = AxiosError;

type TVariables = {
  aggSheetId: number;
  body: BodyType;
};

type Options = Omit<UseMutationOptions<TData, TError, TVariables, unknown>, 'mutationFn'>;
type ReturnType = UseMutationResult<TData, TError, TVariables>;

export function useEditAggSheetsRelationsApi(options?: Options): ReturnType {
  const { refetch: getAggSheetsRelationsApi } = useGetAggSheetsRelationsApi({ enabled: false });
  const toast = useCustomToast();

  const mutationFn = async ({ aggSheetId, body }: TVariables) => {
    const fullEndpoint = `${endpoint}/${aggSheetId}`;

    try {
      const { data } = await http.patch(fullEndpoint, body);

      // Refetch all the agg sheets relations
      getAggSheetsRelationsApi();

      toast({
        description: 'Agg. Sheet relation updated successfully',
        status: 'success',
      });

      return data;
    } catch (error: any) {
      if (!error.response.status || !error.response.data.message) throw error;

      toast({
        description: error.response.data.message,
        status: 'error',
      });

      throw error;
    }
  };

  return useMutationWrapper(mutationFn, { mutationKey: endpoint, ...options });
}
