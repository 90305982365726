import { Layout, Section } from 'layout/index';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { CSVDownloadScreen } from './screens/csv-download-screen';

const BenchmarkingPage = () => {
  const match = useRouteMatch();

  return (
    <Layout type={'sidebar_page_layout'}>
      <Section name={'content'}>
        <Switch>
          <Route path={`${match.url}`} component={CSVDownloadScreen} />
          <Redirect to={`${match.url}`} exact />
          <Route path='*' render={() => <Redirect to='/404' />} />
        </Switch>
      </Section>
    </Layout>
  );
};

export default BenchmarkingPage;
