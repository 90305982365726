import { BasePagination, formatDateTime } from '@elkaso-app/web-design';
import { PosIntegrationData, useGetPosIntegrationsApi } from 'apis/pos-integrations/use-get-pos-integrations-api';
import { BaseTable, BaseTableContainer } from 'components/base-table';
import {
  BaseTableSpinner,
  BaseTableTitle,
  ClearFilterButton,
  CustomTableCell,
  TableCell,
  TableHeader,
} from 'components/base-table/components';
import { CreatedAtFilterButton } from 'components/base-table/filters';
import { BaseSortButton } from 'components/base-table/sorting';
import { useSetPaginationParamsV1 } from 'hooks/use-set-pagination-params-v1';
import React, { useEffect } from 'react';
import { CellProps } from 'react-table';
import { changeTitle } from 'redux/actions';
import { useAppDispatch } from 'redux/hooks';
import { ActionsCell, ActionsCellData } from '../components/actions-cell';
import { PosIntegrationInfoCell, PosIntegrationInfoCellData } from '../components/pos-integration-info-cell';
import { RestaurantInfoCell, RestaurantInfoCellData } from '../components/restaurant-info-cell';
import { UserInfoCell, UserInfoCellData } from '../components/user-info-cell';

export const PosIntegrationsListScreen = () => {
  const setPaginationParams = useSetPaginationParamsV1();
  const dispatch = useAppDispatch();
  const { isLoading, isFetching, data } = useGetPosIntegrationsApi();
  const { pageCount, total, data: posIntegrationsData } = data ?? { pageCount: 0, total: 0 };

  useEffect(() => {
    dispatch(changeTitle('POS Integrations'));
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: <TableHeader name='Integration' sortButtonComponent={<BaseSortButton sortBy='integrationId' />} />,
        accessor: 'integration',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['integration']>) => (
          <CustomTableCell<PosIntegrationInfoCellData>
            as={PosIntegrationInfoCell}
            integrationId={value.integrationId}
            name={value.name}
            imageUrl={value.imageUrl}
          />
        ),
      },
      {
        Header: <TableHeader name='Restaurant' sortButtonComponent={<BaseSortButton sortBy='restaurantId' />} />,
        accessor: 'restaurant',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['restaurant']>) => (
          <CustomTableCell<RestaurantInfoCellData>
            as={RestaurantInfoCell}
            restaurantId={value.restaurantId}
            name={value.name}
          />
        ),
      },
      {
        Header: <TableHeader name='Owner' sortButtonComponent={<BaseSortButton sortBy='userId' />} />,
        accessor: 'user',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['user']>) => (
          <CustomTableCell<UserInfoCellData>
            as={UserInfoCell}
            userId={value.userId}
            name={value.name}
            email={value.email}
            phoneNumber={value.phoneNumber}
          />
        ),
      },
      {
        Header: (
          <TableHeader
            name='Created'
            filterButtonComponent={<CreatedAtFilterButton />}
            sortButtonComponent={<BaseSortButton sortBy='createdAt' />}
          />
        ),
        accessor: 'createdAt',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['createdAt']>) => (
          <TableCell value={formatDateTime(value)} />
        ),
      },
      {
        Header: <TableHeader name='Actions' />,
        accessor: 'actions',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['actions']>) => (
          <CustomTableCell<ActionsCellData> as={ActionsCell} integration={value} />
        ),
      },
    ],
    []
  );

  interface IColumnsAccessorData {
    id: number;
    integration: {
      integrationId: number;
      name: string;
      imageUrl: string;
    };
    restaurant: {
      restaurantId: number;
      name: string;
    };
    user: {
      userId: number;
      name: string;
      email: string;
      phoneNumber: string;
    };
    createdAt: string;
    actions: PosIntegrationData;
  }

  const columnsAccessorData = React.useMemo(
    () =>
      posIntegrationsData?.map((item: (typeof posIntegrationsData)[0]): IColumnsAccessorData => {
        return {
          id: item?.id,
          integration: {
            integrationId: item?.posVendor?.id,
            name: item?.posVendor?.name,
            imageUrl: item?.posVendor?.imageUrl,
          },
          restaurant: {
            restaurantId: item?.restaurant?.id,
            name: item?.restaurant?.nameEn,
          },
          user: {
            userId: item?.user?.id,
            name: item?.user?.name,
            email: item?.user?.email,
            phoneNumber: item?.user?.phoneNumber,
          },
          createdAt: item?.createdAt,
          actions: item,
        };
      }),
    [posIntegrationsData]
  );

  return (
    <BaseTableContainer isLoading={isLoading}>
      <BaseTableContainer.Header>
        <BaseTableContainer.Header.Left>
          <BaseTableTitle title='POS Integrations' />
          <BaseTableSpinner isFetching={isFetching} />
        </BaseTableContainer.Header.Left>

        <BaseTableContainer.Header.Right>
          <ClearFilterButton />
        </BaseTableContainer.Header.Right>
      </BaseTableContainer.Header>

      <BaseTableContainer.TableWrapper>
        <BaseTable columns={columns} data={columnsAccessorData} />
      </BaseTableContainer.TableWrapper>

      <BaseTableContainer.Footer>
        <BaseTableContainer.Footer.Left />
        <BaseTableContainer.Footer.Right>
          <BasePagination>
            <BasePagination.Pages pagesCount={pageCount} setSearchParams={setPaginationParams} />
            <BasePagination.Limit total={total} setSearchParams={setPaginationParams} />
          </BasePagination>
        </BaseTableContainer.Footer.Right>
      </BaseTableContainer.Footer>
    </BaseTableContainer>
  );
};
