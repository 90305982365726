import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  IconButtonWithTooltip,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  MultiValuesInput,
  OnAddItemProps,
  OnRemoveItemProps,
  Select,
  useDisclosure,
  VStack,
} from '@elkaso-app/web-design';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEditAggSheetsRelationsApi } from 'apis/agg-sheets-relations/use-edit-agg-sheets-relations-api';
import { useGetAggSheetsRelationByIdApi } from 'apis/agg-sheets-relations/use-get-agg-sheets-relation-by-id-api';
import { useTestAggSheetsRelationsApi } from 'apis/agg-sheets-relations/use-test-agg-sheets-relations-api';
import { useEffect, useMemo } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { HiPencil } from 'react-icons/hi';
import { SiSpeedtest } from 'react-icons/si';
import * as yup from 'yup';
import { AggSheetRelation } from '../types';

type Recipients = {
  to: string[];
  cc?: string[];
  bcc?: string[];
};

type TInputs = {
  processingBufferTime?: number;
  recipients?: Recipients;
  includeOrders: string[];
  excludeOrders: string[];
  templateName?: string;
};

const defaultValues: TInputs = {
  processingBufferTime: 0,
  recipients: {
    to: [],
    cc: [],
    bcc: [],
  },
  includeOrders: [],
  excludeOrders: [],
  templateName: '',
};

const schema = yup.object().shape({
  processingBufferTime: yup
    .number()
    .typeError('Processing buffer time must be a number')
    .min(0, 'Processing buffer time must be a positive number')
    .integer('Processing buffer time must be an integer')
    .required('Processing buffer time is required'),
  recipients: yup.object().shape({
    to: yup
      .array()
      .of(yup.string().email('Invalid email address'))
      .required('At least one recipient is required')
      .min(1, 'At least one recipient is required'),
    cc: yup.array().of(yup.string().email('Invalid email address')),
    bcc: yup.array().of(yup.string().email('Invalid email address')),
  }),
  includeOrders: yup.array().of(yup.string().matches(/^[A-Za-z]+$/, 'Only English letters are allowed')),
  excludeOrders: yup.array().of(yup.string().matches(/^[A-Za-z]+$/, 'Only English letters are allowed')),
});

interface IEditAggSheetModal {
  aggSheet: AggSheetRelation;
  variant: 'edit' | 'test';
}

export const EditAggSheetModal = ({ aggSheet, variant = 'edit' }: IEditAggSheetModal) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const isEditMode = useMemo(() => {
    return variant === 'edit';
  }, [variant]);

  const isTestMode = useMemo(() => {
    return variant === 'test';
  }, [variant]);

  const { mutate: editAggSheetsRelationsApi, isLoading: isLoadingEditAggSheetsRelationsApi } =
    useEditAggSheetsRelationsApi();

  const { mutate: testAggSheetsRelationsApi, isLoading: isLoadingTestAggSheetsRelationsApi } =
    useTestAggSheetsRelationsApi();

  const {
    refetch: refetchAggSheetRelationByIdApi,
    data: aggSheetRelationData,
    isLoading: isLoadingGetAggSheetsRelationByIdApi,
  } = useGetAggSheetsRelationByIdApi(aggSheet.id);

  useEffect(() => {
    if (isOpen) {
      refetchAggSheetRelationByIdApi();
    }
  }, [isOpen]);

  const {
    handleSubmit,
    control,
    reset,
    watch,
    setError,
    setValue,
    formState: { errors },
  } = useForm<TInputs>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (aggSheetRelationData && isEditMode) {
      reset({
        processingBufferTime: aggSheetRelationData.processingBufferTime,
        recipients: aggSheetRelationData.recipients,
        templateName: aggSheetRelationData.templateName,
      });
    }
  }, [aggSheetRelationData]);

  const toEmailsListValue = watch('recipients.to');
  const ccEmailsListValue = watch('recipients.cc');
  const bccEmailsListValue = watch('recipients.bcc');

  const includeOrdersValue = watch('includeOrders');
  const excludeOrdersValue = watch('excludeOrders');

  const _onClose = () => {
    onClose();
    reset();
  };

  const onSubmit: SubmitHandler<TInputs> = (data) => {
    if (isEditMode) {
      const variables = {
        aggSheetId: aggSheet.id,
        body: {
          processingBufferTime: data.processingBufferTime,
          recipients: data.recipients,
          templateName: data.templateName,
        },
      };

      editAggSheetsRelationsApi(variables, {
        onSuccess: () => {
          _onClose();
        },
      });
    }

    if (isTestMode) {
      const variables = {
        aggSheetId: aggSheet.id,
        body: {
          recipients: data.recipients,
          includeOrders: data.includeOrders || [],
          excludeOrders: data.excludeOrders || [],
        },
      };

      testAggSheetsRelationsApi(variables, {
        onSuccess: () => {
          _onClose();
        },
      });
    }
  };

  const handleEmailAdd = (
    category: 'to' | 'cc' | 'bcc',
    { value, clearInput }: OnAddItemProps,
    emailsListValue: string[] | undefined
  ) => {
    if (!value) return;

    const isValueExists = emailsListValue?.find((s) => s === value);
    if (isValueExists)
      return setError(`recipients.${category}`, { message: 'Email already exists' }, { shouldFocus: true });

    try {
      schema.fields.recipients.fields[category].validateSync([value], { abortEarly: false });
      setValue(`recipients.${category}`, [...(emailsListValue || []), value], { shouldValidate: true });
      clearInput();
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        setError(`recipients.${category}`, { message: 'Invalid email address' }, { shouldFocus: true });
      }
    }
  };

  const handleEmailRemove = (
    category: 'to' | 'cc' | 'bcc',
    { item }: OnRemoveItemProps,
    emailsListValue: string[] | undefined
  ) => {
    setValue(
      `recipients.${category}`,
      emailsListValue?.filter((s) => s !== item),
      { shouldValidate: true }
    );
  };

  const onToEmailsAdd = (props: OnAddItemProps) => handleEmailAdd('to', props, toEmailsListValue);
  const onToEmailsRemove = (props: OnRemoveItemProps) => handleEmailRemove('to', props, toEmailsListValue);

  const onCcEmailsAdd = (props: OnAddItemProps) => handleEmailAdd('cc', props, ccEmailsListValue);
  const onCcEmailsRemove = (props: OnRemoveItemProps) => handleEmailRemove('cc', props, ccEmailsListValue);

  const onBccEmailsAdd = (props: OnAddItemProps) => handleEmailAdd('bcc', props, bccEmailsListValue);
  const onBccEmailsRemove = (props: OnRemoveItemProps) => handleEmailRemove('bcc', props, bccEmailsListValue);

  const handleOrderCodeAdd = (
    key: 'includeOrders' | 'excludeOrders',
    { value, clearInput }: OnAddItemProps,
    orderCodesValue: string[] | undefined
  ) => {
    if (!value) return;

    const standardizeValue = value.toUpperCase();
    const isValueExists = orderCodesValue?.find((s) => s === standardizeValue);

    if (isValueExists) return setError(key, { message: 'code already exists' }, { shouldFocus: true });

    try {
      schema.fields[key].validateSync([standardizeValue], { abortEarly: false });
      setValue(key, [...(orderCodesValue || []), standardizeValue], { shouldValidate: true });
      clearInput();
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        setError(key, { message: 'Only English alphabet letters are allowed' }, { shouldFocus: true });
      }
    }
  };

  const handleOrderCodeRemove = (
    key: 'includeOrders' | 'excludeOrders',
    { item }: OnRemoveItemProps,
    orderCodesValue: string[]
  ) => {
    setValue(
      key,
      orderCodesValue?.filter((s) => s !== item),
      { shouldValidate: true }
    );
  };

  const onIncludeOrdersAdd = (props: OnAddItemProps) => handleOrderCodeAdd('includeOrders', props, includeOrdersValue);
  const onExcludeOrdersAdd = (props: OnAddItemProps) => handleOrderCodeAdd('excludeOrders', props, excludeOrdersValue);

  const onIncludeOrdersRemove = (props: OnRemoveItemProps) =>
    handleOrderCodeRemove('includeOrders', props, includeOrdersValue);
  const onExcludeOrdersRemove = (props: OnRemoveItemProps) =>
    handleOrderCodeRemove('excludeOrders', props, excludeOrdersValue);

  return (
    <>
      <IconButtonWithTooltip
        size='sm'
        label={isEditMode ? 'Edit' : 'Test'}
        aria-label='edit-agg-sheet-btn'
        Icon={isEditMode ? <HiPencil size='22px' /> : <SiSpeedtest size='20px' />}
        variant='unstyled'
        onClick={onOpen}
      />

      <Modal isOpen={isOpen} onClose={_onClose} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{`${isEditMode ? 'Edit' : 'Test'} Agg Sheet Relation`}</ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            <form id='editAggSheetForm' onSubmit={handleSubmit(onSubmit)} noValidate>
              <VStack spacing='lg' align='stretch'>
                {isEditMode && (
                  <VStack spacing='md' align='stretch'>
                    <Heading fontSize='md'>Processing Buffer Time</Heading>

                    <VStack spacing='md'>
                      <Controller
                        control={control}
                        name='processingBufferTime'
                        render={({ field: { name, value, onBlur, onChange }, fieldState: { error, invalid } }) => (
                          <FormControl isInvalid={invalid} isRequired isDisabled={isLoadingGetAggSheetsRelationByIdApi}>
                            <FormLabel color='gray.500' fontSize='sm'>
                              Minutes
                            </FormLabel>
                            <Input
                              type='number'
                              inputMode='numeric'
                              name={name}
                              value={value}
                              onBlur={onBlur}
                              onChange={onChange}
                            />
                            <FormErrorMessage>{error?.message}</FormErrorMessage>
                          </FormControl>
                        )}
                      />
                    </VStack>
                  </VStack>
                )}

                {isEditMode && (
                  <VStack spacing='md' align='stretch'>
                    <Heading fontSize='md'>Templates</Heading>
                    <Controller
                      control={control}
                      name='templateName'
                      render={({ field: { name, value, onBlur, onChange }, fieldState: { error, invalid } }) => (
                        <FormControl isInvalid={invalid} isDisabled={isLoadingGetAggSheetsRelationByIdApi}>
                          <FormLabel color='gray.500' fontSize='sm'>
                            Selection Type
                          </FormLabel>
                          <Select name={name} value={value} onBlur={onBlur} onChange={onChange}>
                            <option value='standard'>Standard</option>
                            <option value='standard_inverted'>Standard Inverted</option>
                            <option value='brands_grouped'>Brands Grouped</option>
                            <option value='standard_with_processing_days'>Standard with Processing Days</option>
                            <option value='standard_with_service_regions'>Standard with Service Regions</option>
                          </Select>
                          <FormErrorMessage>{error?.message}</FormErrorMessage>
                        </FormControl>
                      )}
                    />
                  </VStack>
                )}

                <VStack spacing='md' align='stretch'>
                  <Heading fontSize='md'>Recipients</Heading>

                  <VStack spacing='md'>
                    <FormControl
                      isInvalid={!!errors.recipients?.to?.message}
                      isRequired
                      isDisabled={isLoadingGetAggSheetsRelationByIdApi}>
                      <FormLabel color='gray.500' fontSize='sm'>
                        Emails (to)
                      </FormLabel>

                      <MultiValuesInput.Input variant='flushed' onAddItem={onToEmailsAdd} />
                      <FormErrorMessage>{errors.recipients?.to?.message}</FormErrorMessage>

                      <MultiValuesInput.List valuesArray={toEmailsListValue} onRemoveItem={onToEmailsRemove} />
                    </FormControl>

                    <FormControl
                      isInvalid={!!errors.recipients?.cc?.message}
                      isDisabled={isLoadingGetAggSheetsRelationByIdApi}>
                      <FormLabel color='gray.500' fontSize='sm'>
                        Emails (cc)
                      </FormLabel>

                      <MultiValuesInput.Input variant='flushed' onAddItem={onCcEmailsAdd} />
                      <FormErrorMessage>{errors.recipients?.cc?.message}</FormErrorMessage>

                      <MultiValuesInput.List valuesArray={ccEmailsListValue ?? []} onRemoveItem={onCcEmailsRemove} />
                    </FormControl>

                    <FormControl
                      isInvalid={!!errors.recipients?.bcc?.message}
                      isDisabled={isLoadingGetAggSheetsRelationByIdApi}>
                      <FormLabel color='gray.500' fontSize='sm'>
                        Emails (bcc)
                      </FormLabel>

                      <MultiValuesInput.Input variant='flushed' onAddItem={onBccEmailsAdd} />
                      <FormErrorMessage>{errors.recipients?.bcc?.message}</FormErrorMessage>

                      <MultiValuesInput.List valuesArray={bccEmailsListValue ?? []} onRemoveItem={onBccEmailsRemove} />
                    </FormControl>
                  </VStack>
                </VStack>

                {isTestMode && (
                  <VStack spacing='md' align='stretch'>
                    <Heading fontSize='md'>Include / Exclude Orders</Heading>

                    <VStack spacing='md'>
                      <FormControl
                        isInvalid={!!errors.includeOrders?.message}
                        isRequired
                        isDisabled={isLoadingGetAggSheetsRelationByIdApi}>
                        <FormLabel color='gray.500' fontSize='sm'>
                          Include Order Codes
                        </FormLabel>

                        <MultiValuesInput.Input variant='flushed' onAddItem={onIncludeOrdersAdd} />
                        <FormErrorMessage>{errors.includeOrders?.message}</FormErrorMessage>

                        <MultiValuesInput.List valuesArray={includeOrdersValue} onRemoveItem={onIncludeOrdersRemove} />
                      </FormControl>

                      <FormControl
                        isInvalid={!!errors.excludeOrders?.message}
                        isRequired
                        isDisabled={isLoadingGetAggSheetsRelationByIdApi}>
                        <FormLabel color='gray.500' fontSize='sm'>
                          Exclude Order Codes
                        </FormLabel>

                        <MultiValuesInput.Input variant='flushed' onAddItem={onExcludeOrdersAdd} />
                        <FormErrorMessage>{errors.excludeOrders?.message}</FormErrorMessage>

                        <MultiValuesInput.List valuesArray={excludeOrdersValue} onRemoveItem={onExcludeOrdersRemove} />
                      </FormControl>
                    </VStack>
                  </VStack>
                )}
              </VStack>
            </form>
          </ModalBody>

          <ModalFooter>
            <HStack spacing='md'>
              <Button variant='outline' colorScheme='red' onClick={_onClose}>
                Cancel
              </Button>

              <Button
                variant='solid'
                colorScheme='red'
                type='submit'
                form='editAggSheetForm'
                isLoading={isLoadingEditAggSheetsRelationsApi || isLoadingTestAggSheetsRelationsApi}>
                {`${isEditMode ? 'Save' : 'Send'}`}
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
