import { StackProps, Text, VStack } from '@elkaso-app/web-design';
import { Link } from 'react-router-dom';
import { PhoneNumber } from '../../../components/base-table';

export interface UserInfoCellData extends StackProps {
  userId: number;
  name: string;
  email: string;
  phoneNumber: string;
}

export const UserInfoCell = ({ name, email, phoneNumber, ...rest }: UserInfoCellData) => {
  return (
    <VStack spacing='sm' align='start' maxW='200px' {...rest}>
      <Link to={`/app/elkasoUsers/list?limit=45&page=1&phoneNumber=${encodeURIComponent(phoneNumber)}`}>
        <Text
          whiteSpace='pre-line'
          color={'red.500'}
          fontWeight='500'
          _hover={{ color: 'red.500' }}
          _focus={{ color: 'red.500' }}>
          {name}
        </Text>
      </Link>

      <Text whiteSpace='pre-line' fontSize='md' color='gray.500'>
        {email}
      </Text>

      <PhoneNumber value={phoneNumber} />
    </VStack>
  );
};
