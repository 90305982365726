module.exports = {
  // ---------------------  sidebar  -------------------------
  'sidebar.catalogManagement': 'إدارة الكتالوج',
  'sidebar.benchmarking': 'المرجعية',
  'sidebar.aggSheetRelations': 'الأوراق المجمعة',
  'sidebar.ticketManagement': 'إدارة التذاكر',
  'sidebar.partners': 'الشركاء',
  'sidebar.partner': 'معلومات الشريك',
  'sidebar.userManagement': 'إدارةالمستخدم',
  'sidebar.elkasoUsers': 'مستخدمو التطبيق',
  'sidebar.suppliers': 'المورّدين',
  'sidebar.restaurants': 'المطاعم',
  'sidebar.invitationRequests': 'الدعوات',
  'sidebar.units': 'الوحدات',
  'sidebar.Branches': 'فروع المطعم',
  'sidebar.restaurant.users': 'مستخدمو المطعم',
  'sidebar.supplier.users': 'المستخدمين',
  'sidebar.userProfile': 'الحساب الشخصي',
  'sidebar.offers': 'العروض',
  'sidebar.fullScreen': 'ملء الشاشة',
  'sidebar.darkMode': 'الوضع الليلي',
  'sidebar.lightMode': 'الوضع النهاري',
  'sidebar.close': 'إغلاق',
  'sidebar.open': 'فتح',
  'sidebar.services': 'خدمات',
  'sidebar.employee': 'المستخدمين',
  'sidebar.serviceCategories': 'فئات الخدمات',
  'sidebar.serviceOffers': 'العروض',
  'sidebar.partnerCategory': 'فئات الشركاء',
  'sidebar.customers': 'الزبائن',
  'sidebar.orders': 'الطلبات',
  'sidebar.reports': 'التقارير',
  'sidebar.reports.partnerPerformance': 'أداء الشركاء',
  'sidebar.reports.customerPerformance': 'أداء الزبائن',
  'sidebar.reports.financialReport': 'المالي',
  'sidebar.reports.joining': 'المستخدمون المسجلون',
  'sidebar.reports.employees': 'أداء المستخدمين',
  'sidebar.reports.partnerDashboard': 'منصة الشريك',
  'sidebar.settings': 'الإعدادات',
  'sidebar.help': 'مساعدة',
  'sidebar.OTP': 'طلبات كلمة السر',
  'sidebar.aboutUS': 'من نحن',
  'sidebar.statistics': 'الإحصائيات',
  'sidebar.analytics': 'التحليلات',
  'sidebar.promotions': 'العروض',
  'sidebar.categories': 'الفئات',
  'sidebar.permissions': 'التفضيلات',
  'sidebar.otp': 'قائمة طلب كود تفعيل',
  'sidebar.whatsNumbers': 'قائمة أرقام الواتس',
  'sidebar.countries': 'البلدان',
  'sidebar.restaurantTransfers': 'تحويلات المطاعم',
  'sidebar.posIntegrations': 'تكامل نقاط البيع',
  'sidebar.products': 'تكامل نقاط البيع',
  // ---------------------  header  -------------------------
  'title.languages': 'اللغة',
  // ---------------------  Pagination  -------------------------
  'pagination.count': '{from} - {to} من {count} سطر',
  'global.tablePagination.label': 'Rows per page',
  'global.table.display': 'إظهار {value} سطر من {total}',
  // ---------------------  widgets  -------------------------

  'widgets.profile': 'الحساب الشخصي',
  'widgets.logOut': 'تسجيل الخروج',
  'widgets.recentAlerts': 'التنبيهات',
  'widgets.moreAction': 'المزيد',
  'widgets.appointment': 'المواعيد',
  'widgets.offerHistory': 'سجل العرض',
  'widget.copy': 'نسخ',
  'widget.paste': 'لصق',
  'widget.copyAll': 'نسخ ساعات العمل',
  'widget.pasteAll': 'لصق ساعات العمل',
  'widget.resetAll': 'إعادة تهيئة ساعات العمل',
  'notification.new': 'الحديثة',
  'notification.old': 'القديمة',
  'notification.countOfNotification': 'عدد التنبيهات',
  'notification.user.login': 'تم تسجيل الدخول بنجاح',
  'notification.user.logout': 'تم تسجيل الخروج بنجاح',
  'notification.inactive_user': 'حساب غير مفعل! يُرجى تفعيل الحساب ثم تسجيل الدخول مرة أُخرى',
  'notification.Unauthorized': 'لا يوجد لديك صلاحيّة للدخول, الرجاء المحاولة لاحقاً',
  'notification.invalid_credentials': 'لا يوجد لديك صلاحيّة للدخول, الرجاء المحاولة لاحقاً',
  'notification.exceeded_the_number_of_requests': 'لقد تجاوزت عدد المحاولات المسموحة قم بإعادة المحاولة لاحقاً',
  'notifications.success': 'نجاح !',
  'notifications.error': 'خطأ !',
  // ---------------------  Actions  -------------------------
  'global.actions': 'المهام',
  'global.actions.open': 'فتح',
  'global.actions.close': 'إغلاق',
  'global.actions.create': 'إنشاء',
  'global.actions.delete': 'حذف',
  'global.actions.update': 'تحديث',
  'global.actions.selectAll': 'اختيار الكل',
  'global.actions.attach': 'إضافة مورّد',
  'global.actions.view': 'إظهار',
  'global.actions.generate': 'توليد',
  'global.actions.Filter': 'إضافة الفلتر',
  'global.actions.showFilters': 'إظهار الفلتر',
  'global.actions.save': 'حفظ',
  'global.action.showAll': 'إظهار المزيد',
  'widgets.editAction': 'تعديل',
  'widgets.deleteAction': 'حذف',
  'widgets.reply': 'رد',
  'widgets.viewAction': 'طلب OTP',
  'components.submit': 'إرسال',
  'components.set.password': 'تغيير كلمة المرور',
  'components.change.password': 'تحديث كلمة المرور',
  'login.actions.signin': 'تسجيل الدخول',
  'error.required': 'هذه الحقول مطلوبة',
  'login.actions.reset': 'إعادة تعيين كلمة المرور',
  'login.actions.back': 'العودة إلى الصفحة الرئيسية',
  'login.actions.forgetPassword': 'هل نسيت كلمة المرور؟',
  'login.forgetPassword': 'نسيت كلمة المرور',
  'login.forgetPassword.info': 'من فضلك أدخل بريدك الإلكتروني لإعادة تعيين كلمة المرور',
  'login.backLogin': 'العودة إلى صفحة تسجيل الدخول',
  'login.info': 'من فضلك أدخل معلومات حسابك',
  'login.setPassword': 'إعادة تعيين كلمة المرور',
  'login.setPassword.info': 'من فضلك أدخل كلمة المرور الجديدة',
  'global.details.set.password': 'تم إرسال بريد إلكتروني لك يحتوي على إرشادات حول إعادة تعيين كلمة المرور الخاص بك',
  'global.title.set.password': 'إعادة تعيين كلمة المرور',
  'global.title.token.expired': 'Oops! Token Expired!',
  'global.details.token.expired': "Your token isn't valid",
  'global.details.session.expired': 'Your session expired, please tell the management to reactivate your account',
  'global.title.session.expired': 'Oopsss! Session Expired',
  'global.title.access.denied': 'عُذراً! ممنوع الدخول',
  'button.cancel': 'إلغاء',
  'button.save': 'حفظ',
  'button.add': 'إضافة',
  'button.edit': 'تعديل',
  'button.yes': 'نعم',

  'form.basic.info': 'المعلومات الأساسية',
  'form.other.info': 'المعلومات الأخرى',
  'form.activation': 'تفعيل الشريك',
  'form.opening.hours': 'ساعات العمل',
  'form.opening.hours.days': 'الأيام',
  'form.opening.hours.open': 'من الساعة',
  'form.opening.hours.close': 'إلى الساعة',
  'form.opening.hours.workingDay': 'مفتوح',
  'form.opening.hours.twoShifts': 'دوام ثاني ',
  'form.opening.hours.errors.400': 'يجب تحديد وقت بدء وانتهاءالعمل',
  'form.opening.hours.errors.401': 'يجب تحديد وقت انتهاءالعمل ',

  'global.day.sat': 'السبت',
  'global.day.sun': 'الأحد',
  'global.day.mon': 'الاثنين',
  'global.day.tue': 'الثلاثاء',
  'global.day.wed': 'الأربعاء',
  'global.day.thu': 'الخميس',
  'global.day.fri': 'الجمعة',

  // ---------------------  Global Messages  -------------------------
  'global.options.noMatched': 'لا يوجد خيارات',
  'global.project.name': 'Elkaso',
  'global.empty': 'لا يوجد بيانات',
  'global.noData': 'لا يوجد بيانات',
  'global.error.somethingWentWrong': 'عذراً, شيء ما حدث بشكل خاطئ',
  'global.true': 'نعم',
  'global.false': 'لا',
  'global.activate.true': 'تفعيل',
  'global.deactivate.false': 'إلغاء التفعيل',
  'global.success.message.new': 'تم الإضافة بنجاح',
  'global.success.message.edit': 'تم التعديل بنجاح',
  'global.success.message.reassign': 'تمت إعادة تعيين الطلب بنجاح ',
  'global.error.message.employee_cannot_serve': 'لا يستطيع المستخدم تخديم الطلب',
  'global.error.change.status': 'لا يمكن تغيير حالة الطلب',
  'global.error.change.sameStatus': 'الحالة موجودة سابقاً، يرجى تحديد حالة أخرى',
  'global.error.oldPassword': 'كلمة المرور القديمة غير صحيحة',
  'global.errors.notUpload': 'هناك خطأ! لا يمكن رفع هذه الصورة',
  'global.errors.multi': 'لا يمكنك تحميل أكثر من صورة!',
  'global.select.none': 'غير محدد',
  'global.select.noItems': 'لا يوجد عناصر للإضافة',
  'global.success.message.cancel': 'تم إلغاء الطلب بنجاح',
  'global.createdAt': 'تاريخ الإنشاء',
  'global.image': 'الصورة',
  'global.actions.expand': 'تكبير',
  'global.title.page.notfound': 'عُذراً! الصفحة غير موجودة',
  'global.details.access.denied': 'يبدو أنك اتجهت إلى مكان غير مخصص لك، ولا يمكننا مساعدتك هنا',
  'global.button.ok': 'تم',
  'global.button.gotIt': 'تم',
  'global.crop': 'قص',
  'global.crop.image': 'قص صورة',
  'button.anotherImage': 'حسناً',
  'global.empty.list': 'القائمة فارغة!',
  'global.loading': 'جاري تحميل الصفحة',

  // ---------------------  Errors  -------------------------

  'global.errors.required': 'هذا الحقل مطلوب',
  'global.errors.differenceTime': 'يُرجى التأكد من ساعات العمل',
  'global.errors.wrongTime': 'وقت خاطئ',
  'global.errors.email.notValid': 'البريد الإلكتروني غير صالح',
  'global.errors.email.unique': 'البريد الإكتروني مستخدم سابقاً',
  'global.errors.phone_number.notValid': 'رقم الهاتف غير صالح',
  'global.errors.phone_number_already_exists.unique': 'رقم الهاتف مستخدم مسبقاً!',
  'global.errors.countryCode.notValid': 'رمز البلد غير صالح!',
  'global.errors.phone_number_countryCode.unique': 'رمز البلد غير صالح!',
  'global.errors.phone.notValid': 'رقم الهاتف غير صالح',
  'global.errors.roadAssistantWithWarranty.notValid': 'رقم مساعد خدمات الطرق ضمن الكفالة غير صالح',
  'global.errors.roadAssistantWithoutWarranty.notValid': 'رقم مساعد خدمات الطرق خارج الكفالة غير صالح',
  'global.errors.whatsApp.notValid': 'رقم الواتس أب غير صالح',
  'global.errors.phone_number.unique': 'رقم الهاتف مستخدم سابقاً',
  'global.errors.name.unique': 'الاسم مكرر!',
  'global.errors.coordinates.unique': 'العنوان على الخريطة مكرر!',
  'global.errors.differenceShifts': 'وقت الانتهاء يجب أن يكون أكبر من وقت البدء',
  'global.errors.differenceTwoShifts': 'وقت بدء العمل الإضافي يجب أن يكون أكبر من وقت انتهاء وقت العمل الأساسي',
  'global.errors.fill': 'كل الحقول الإجبارية يجب أن تكون مملوءة',
  'global.errors.duration.notValid': 'يُرجى إدخال مدة من مضاعفات ال 30 دقيقة',
  'global.errors.duration.negative': 'يُرجى إدخال مدة صالحة',
  'global.errors.101': 'لا يوجد تصريح لدخولك، يرجى تسجيل الدخول مرة أخرى',
  'global.errors.102': 'الإصدار الحالي قديم جداً',
  'global.errors.103': 'عذراً، حدث خطأ ما حاول مرة اخرى',
  'global.errors.104': 'عذراً، ليس لديك صلاحية للوصول إلى هنا',
  'global.errors.105': 'عذراً، الصفحة التي طلبتها غير موجودة',
  'global.errors.107': 'عذراً، ليس لديك صلاحية للوصول إلى هنا',
  'global.errors.108': 'عذراً، حدث خطأ ما حاول مرة اخرى',
  'global.errors.802': 'الرمز موجود مسبقاً',
  'global.errors.404': 'حدث خطأ غير معروف، يرجى المحاولة مرة أخرى',
  'global.errors.500': 'حدث خطأ غير معروف، يرجى المحاولة مرة أخرى',
  'dialog.title.error': 'خطأ',
  'global.errors.date': 'تاريخ غير صالح',
  'global.error.negative': 'القيمة يجب أن تكون موجبة وأكبر من الصفر!',

  // ---------------------  Users  -------------------------
  'users.form.new.title': 'إضافة مستخدم',
  'users.form.edit.title': 'تعديل المستخدم',
  'users.form.view.title': 'عرض المستخدم',
  'users.form.edit.success': 'تم تعديل المستخدم بنجاح',
  'user.name': 'اسم المستخدم',
  'user.email': 'البريدالإلكتروني',
  'user.phoneNumber': 'رقم الهاتف',
  'user.landLineNumber': 'رقم الهاتف الأرضي',
  'user.active': 'التفعيل',
  'user.role': 'السماحيات',
  'user.roles': 'السماحيات',
  'user.countries': 'البلدان',
  'user.button.new': 'إنشاء مستخدم',
  'user.button.edit': 'تعديل المستخدم',
  'user.createdAt': 'تاريخ الإنشاء',
  'user.password': 'كلمة المرور',
  'user.oldPassword': 'كلمة المرور القديمة',
  'user.newPassword': 'كلمة المرورالجديدة',
  'user.passwordConfirmation': 'تأكيد كلمة المرور',
  'user.password.confirmation': 'كلمة المرور غير متطابقة',
  'user.password.validate': 'يجب أن تحتوي كلمة المرور رقم واحد, حرف واحد كبير وتتألف من 8 محارف على الأقل',
  'user.password.length': 'يجب أن تكون كلمة المرور 8 محارف على الأقل',
  'user.success.change.password': 'تم تغيير كلمة المرور بنجاح',
  'button.activateButton': 'تفعيل',
  'button.deactivateButton': 'إلغاء التفعيل',
  'button.cancelButton': 'إلغاء',
  'user.button.update': 'تحديث الحساب الشخصي',
  'button.delete': 'حذف',
  'user.isVerify': 'التحقق',
  'users.verify.true': 'محقق',
  'users.verify.false': 'غير محقق',
  'dialog.user.hasVerifiedEmail.false': 'إلغاء التحقق من البريد الإلكتروني للمستخدم!',
  'dialog.message.user.hasVerifiedEmail.false': 'هل أنت متأكد أنك تريد إلغاء التحقق من هذا البريد الإلكتروني؟',
  'dialog.user.hasVerifiedEmail.true': 'التحقق من البريد الإلكتروني للمستخدم!',
  'dialog.message.user.hasVerifiedEmail.true': 'هل أنت متأكد أنك تريد التحقق من هذا البريد الإلكتروني؟',
  'button.users.verify': 'تحقق',
  'button.users.unverify': 'إلغاء التحقق',
  // ---------------------  Dialog Messages  -------------------------
  'dialog.title.partner': 'تفعيل / تعطيل حساب الشريك',
  'dialog.message.partner': 'هل أنت متأكد من تعطيل / تفعيل حساب الشريك؟',
  'dialog.customer.info': 'معلومات الزبون',
  'dialog.contact.person': '',
  'dialog.address.map': 'تفاصيل العنوان',
  'dialog.catalog': 'إدارة المنتجات',
  'dialog.address.info': 'معلومات العنوان',
  'dialog.address.fullLine': 'العنوان التفصيلي',
  'dialog.address.nearBy': 'الاماكن المجاورة',
  'dialog.address.villaNumber': 'رقم الفيلا',
  'dialog.address.nearestBuilding': 'أقرب مبنى',
  'dialog.address.label': 'الاسم',
  'dialog.locations': 'العناوين',
  'dialog.order.details': 'تفاصيل الطلب',

  'form.map.info': 'موقع الشريك',
  'map.search': 'البحث عن العنوان',
  'form.zone': 'القطاعات',

  // ---------------------  Address  -------------------------
  'address.label': 'اسم العنوان',
  'address.lat': 'Lat',
  'address.long': 'Long',
  'address.fullLine': 'العنوان الكامل',
  'address.map': 'العنوان على الخريطة',

  // ---------------------  Dialog Messages  -------------------------
  'dialog.extraInfo.user.isActive.false': 'لن يتمكن المستخدم من تسجيل الدخول حتى يتم تفعيله مرة أُخرى!',
  'dialog.extraInfo.user.isActive.true': 'سيكون المستخدم قادر على تسجيل الدخول بعد التفعيل!',
  'dialog.user.isActive.false': 'تفعيل المستخدم!',
  'dialog.user.isActive.true': 'إلغاء تفعيل المستخدم!',
  'dialog.offer.isActive.false': 'إلغاء تفعيل العرض!',
  'dialog.offer.isActive.true': 'تفعيل العرض!',
  'dialog.isActive': 'تفعيل / إلغاء تفعيل',
  'dialog.message.user.isActive.false': 'هل أنت متأكد من إلغاء تفعيل هذا المستخدم؟',
  'dialog.message.user.isActive.true': 'هل أنت متأكد من تفعيل هذا المستخدم؟',
  'dialog.message.offer.isActive.false': 'هل أنت متأكد أنك تريد إلغاء تفعيل هذا العرض؟',
  'dialog.message.offer.isActive.true': 'هل أنت متأكد أنك تريد تفعيل هذا العرض؟',
  'dialog.product.status.inactive': 'إلغاء تفعيل المنتج!',
  'dialog.product.status.active': 'تفعيل المنتج!',
  'dialog.message.product.status.inactive': 'هل أنت متأكد من إلغاء تفعيل هذا المنتج؟',
  'dialog.message.product.status.active': 'هل أنت متأكد أنك تريد تفعيل هذا المنتج؟',
  'dialog.unit.isActive.false': 'إلغاء تفعيل الوحدة!',
  'dialog.unit.isActive.true': 'تفعيل الوحدة!',
  'dialog.message.unit.isActive.false': 'هل أنت متأكد من إلغاء تفعيل هذه الوحدة؟',
  'dialog.message.unit.isActive.true': 'هل أنت متأكد أنك تريد تفعيل هذه الوحدة؟',
  'dialog.restaurant.user.delete': 'حزف مستخدم المطعم',
  'dialog.message.restaurant.user.delete': 'هل أنت متأكد من حزف هذا المستخدم ؟',
  'dialog.products.delete': 'حزف المنتجات!',
  'dialog.message.products.delete': 'هل أنت متأكد من حزف {productsCount} منتج؟',
  'dialog.restaurant.supplier.detach': 'الغاء الارتباط!',
  'dialog.message.restaurant.supplier.detach': 'هل أنت متأكد من الغاء الارتباط مع {supplierName}',

  'dialog.isFeatured': 'شريك مميز',
  'dialog.message.isFeatured.true': 'هل أنت متأكد من إضافة خاصية مميز؟',
  'dialog.message.isFeatured.false': 'هل أنت متأكد من إزالة خاصية مميز ؟',
  'dialog.title.employee_has_appointment': 'الموظف لديه موعد',
  'dialog.message.employee_has_appointment': 'هل أنت متأكد من أنك تريد إعادة تعيين الموظف؟',
  'dialog.employee_cannot_serve': 'لا يمكن للموظف تخديم العرض',
  'dialog.message.employee_cannot_serve': 'هل أنت متأكد من أنك تريد إعادة تعيين الموظف؟',
  'dialog.message.reassign_redirect': '{name} مشغول بالطلب {orderId} ،حدد موظفًا بديلاً للطلب {orderId}',
  'dialog.cancelOrder': 'إلغاء الطلب ',
  'dialog.message.cancelOrder': 'هل أنت متأكد من إلغاء الطلب ؟',
  'dialog.103': 'الوقت الممدد سوف يتجاوز الفترة الزمنية',
  'dialog.101': 'لا يمكن للموظف تقديم العرض',
  'dialog.101-103': 'لا يمكن للموظف تقديم العرض وسوف يتجاوز طول الفترة الزمنية فترة العمل',
  'dialog.message.103': 'هل أنت متأكد من إضافة الخدمة؟',
  'dialog.message.101': 'هل أنت متأكد من إضافة الخدمة؟',
  'dialog.message.101-103': 'هل أنت متأكد من إضافة الخدمة؟',
  'dialog.errors.appointment_overlap': 'لا يمكن للنظام إضافة هذه الخدمة لأن هناك تداخل في الموعد',
  'dialog.errors.appointment_overlap2': 'هناك تداخل بالمواعيد',
  'dialog.errors.out': 'يجب إنشاء الموعد خلال ساعات العمل',
  'dialog.appointment.delete.title': 'تأكيد حذف موعد',
  'dialog.appointment.delete.msg': '( هل أنت متأكد من أنك تريد حذف الموعد؟ (إذا كنت متأكداً اضغط حفظ مرة أخرى',
  'dialog.errors.connot_update_order': 'لا يمكن تحديث الطلب',
  'dialog.warnings.exceeds_shift.title': 'الموعد سيتجاوز ساعات العمل',
  'dialog.warnings.exceeds_shift.message': '(هل أنت متأكد من إضافة هذا الموعد؟ (إذا كنت متأكداّ اضغط حفظ',
  'dialog.error': 'خطأ!',
  'dialog.message.errorExt': 'يمكنك رفع صورة فقط باستخدام هذه اللواحق png, jpg و jpeg!',
  'dialog.createOffer': 'إنشاء عرض!',
  'dialog.updateOffer': 'تحديث العرض!',
  'dialog.message.createMsg': 'هل أنت متأكد أنك تريد إنشاء هذا العرض؟',
  'dialog.message.updateMsg': 'هل أنت متأكد أنك تريد تحديث هذا العرض؟',
  'dialog.attach.supplier': 'الرجاء اختيار المورّد المراد إضافته',
  'dialog.comment.add': 'إضافة ملاحظة',
  'dialog.comment.edit': 'تعديل الملاحظة',
  'dialog.comment': 'الملاحظة',

  // ---------------------  Filters  -------------------------
  'filters.isActive.true': 'نعم',
  'filters.isActive.false': 'لا',

  // ---------------------  Orders  -------------------------
  'orders.items.total': 'مجموع العناصر',
  'orders.items.price': 'الإجمالي (مع الضريبة/ {currency})',
  'orders.items.updatedPrice': 'السعر المعدل ({currency})',
  'order.id': 'رقم الطلب',
  'order.customer': 'الزبائن',
  'order.employee': 'المستخدمين',
  'order.status': 'الحالة',
  'order.paymentMethod': 'طريقة الدفع',
  'order.paymentStatus': 'حالة الدفع',
  'order.notes': 'الملاحظات',
  'order.address': 'العنوان',
  'order.date': 'التاريخ',
  'order.items': 'الطلبات',
  'order.items.name': 'الاسم',
  'order.items.price': 'السعر',
  'order.items.vat': 'نسبة الضريبة % ',
  'order.items.vat.amount': 'قمية الضريبة',
  'order.items.price.vat': 'السعر مع الضريبة',
  'order.items.discount': 'الخصم',
  'order.items.offer.price': 'السعر بعد الخصم',
  'order.total.price': 'الإجمالي : ',
  'order.reassign.title': 'إعادة تعيين مستخدم من أجل الطلب رقم {orderId}',
  'order.changeStatus.title': 'تغيير حالة الطلب رقم {orderId}',
  'order.title': 'الطلب رقم {orderId}',
  'order.transactions.title': 'التحويلات المالية للطلب رقم {orderId}',
  'order.edit.title': 'تعديل الطلب رقم {orderId}',
  'order.reassign.employees': 'المستخدمين',
  'order.edit.service.offers': 'عروض الخدمة',

  'order.transaction.amount': 'الكمية',
  'order.transaction.type': 'النوع',
  'order.transaction.date': 'التاريخ',
  'order.transaction.status': 'الحالة',

  'filters.status.pending': 'قيد الانتظار',
  'filters.status.placed': 'جاري العمل عليه',
  'filters.status.done': 'منتهي',
  'filters.status.canceled': 'ملغى',
  'filters.status.none': 'غير محدد',
  'filters.status.active': 'مفعّل',
  'filters.status.onTheWay': 'قيد التنفيذ',
  'filters.status.paid': 'مدفوع',
  'filters.status.unpaid': 'غير مدفوع',

  'filters.paymentStatus.pending': 'قيد الانتظار',
  'filters.paymentStatus.unpaid': 'غير مدفوع',
  'filters.paymentStatus.paid': 'مدفوع',
  'filters.paymentStatus.refund': 'إعادة',
  'filters.paymentStatus.none': 'ملغاة',

  'filters.paymentMethod.cash': 'نقدي',
  'filters.paymentMethod.payfort': 'Payfort',

  'filters.startDate': 'تاريخ البداية',
  'filters.endDate': 'تاريخ النهاية',

  // ---------------------  Orders  Actions -------------------------

  'orders.actions.edit': 'تعديل الطلب',
  'orders.actions.changeStatus': 'تغيير حالة الطلب',
  'orders.actions.cancel': 'إلغاء الطلب',
  'orders.actions.reassign': 'إعادة تعيين الطلب',
  'orders.actions.transactions': 'التحويلات المالية',
  'orders.actions.orderAudits': 'سجل الطلب',
  'order.history.update': 'تم تحديث حالة الطلب  ',
  'order.history.reassign': 'تم إعادة تعيين الطلب ',
  'order.history.addOffer': 'تم إضافة عرض جديد  ',
  'order.history.removeOffer': 'تم إزالة عرض  ',
  'order.history.cancel': 'تم إلغاء الطلب  ',
  'order.history.complete': ' تم تحديث حالة الطلب إلى مكتمل  ',
  'order.history.title': 'سجل الطلب رقم {orderId}',

  'order.change.status': 'الحالة',
  'global.select.pending': 'قيد الانتظار',
  'global.select.placed': 'تم تقديم الطلب',
  'global.select.onTheWay': 'قيد التنفيذ',
  'global.select.canceled': 'ملغى',
  'global.select.done': 'منتهي',
  'order.change.status.success': 'تم تغيير حالة الطلب بنجاح',

  //---------------  Restaurants -----------
  'orders.vat': 'الضريبة',
  'orders.subtotal.price': 'الإجمالي',
  'restaurants.vat': 'يوجد ضريبة؟',
  'restaurant.vatValue': 'قيمة الضريبة',
  'restaurant.branch.supplier.catalog': 'منتجات موّرد فرع المطعم',
  'restaurant.branches.suppliers': 'قائمة موّردي الفرع',
  'restaurant.supplier.emails.emailcc': 'قائمة مستلمي البريد الإلكتروني',
  'restaurant.supplier.emails': 'عرض قائمة مستلمي البريد الإلكتروني',
  'restaurant.supplier.emailcc': 'مستلمي البريد الإلكتروني بين المطعم {res} والمورّد {supp}',
  'restaurants.empty.suppliers': 'المطعم ليس لديه مورّدين',
  'restaurants.empty.branches': 'المطعم ليس لديه فروع',
  'restaurants.details.creationDate': 'تاريخ الإنشاء: {date}',
  'restaurants.details.total': 'العدد الكلي {total}',
  'restaurants.details.kpi': 'مؤشر قياس أداء المطعم',
  'restaurants.branches': 'الفروع {count}',
  'restaurants.details': 'تفاصيل المطعم',
  'restaurants.editDetails': 'تعديل تفاصيل المطعم',
  'restaurant.orders.list': 'قائمة طلبات المطعم',
  'restaurant.merge.error.branch': 'المطعم الأساسي والمطعم الذي تريد دمجه لديهما نفس اسم الفرع!',
  'restaurants.assign.update.success': 'تم تعديل معلومات المطعم بنجاح',
  'restaurants.assign.success': 'تم تعيين المطعم بنجاح',
  'restaurant.branches.name': 'فروع مطعم {restaurantName}',
  'restaurants.select.admin': 'اكتب اسم المطعم لتحصل على البيانات...',
  'button.submit': 'تأكيد',
  'restaurant.selectAdmin': 'اختر أدمن',
  'restaurant.selectAdmin.method': 'اختيار طريقة تعيين الأدمن',
  'restaurant.assignAdmin.edit': 'تعديل معلومات الأدمن ',
  'restaurant.merge.notNow': 'ليس الآن',
  'restaurant.merge.addNew': 'إضافة أدمن جديد',
  'restaurant.merge.exist': 'اختيار أدمن من المدراء الموجودين',
  'restaurant.assignAdmin.add': 'تعيين أدمن',
  'restaurant.assign': 'تعيين أدمن',
  'restaurant.merge.hasUsers': 'لا يمكنك دمج هذا المطعم لأن لديه أكثر من مستخدم!',
  'restaurant.merge.error': 'المطعم الأساسي والمطعم الذي تريد دمجه يجب أن يكونا في نفس البلد!',
  'dialog.restaurant.confirm.merge': 'دمج المطاعم!',
  'dialog.message.restaurant.merge': 'هل أنت متأكد أنك تريد دمج هذين المطعمين؟',
  'dialog.mergeRestaurant.note':
    'الرجاء كتابة اسم المطعم الذي تريد دمجه مع المطعم الأساسي مع مراعاة أن يكون بنفس بلد المطعم الأساسي',
  'restaurant.name': 'اسم المطعم',
  'dialog.restaurant.merge': 'اختيار المطعم المراد دمجه',
  'restaurant.merge.choose': 'اختر المطعم الذي تريد دمجه',
  'restaurant.holding': 'المطعم الأساسي: {holdingRes} - {admin} بدور أدمن',
  'restaurant.merging': 'المطعم المراد دمجه: {mergedRes}  - {admin} بدور أدمن',
  'restaurant.merge': 'دمج',
  restaurant_manager: 'مدير المطعم',
  restaurant_user: 'مستخدم المطعم',
  'restaurant.restaurant_admin': 'مالك المطعم',
  'restaurant.restaurant_manager': 'مدير المطعم',
  'restaurant.restaurant_user': 'مستخدم المطعم',
  'restaurant.supplier.upload': 'رفع ملف CSV للمورّد من قبل فرع المطعم',
  'restaurant.supplier.catalog': 'منتجات مورّد المطعم',
  'restaurant.branches.users': 'اسم الفرع: {branchName}',
  'restaurant.admin.info': 'معلومات أدمن المطعم',
  'restaurant.name.en': 'اسم المطعم (انكليزي)',
  'restaurant.name.ar': 'اسم المطعم (عربي)',
  'restaurant.branches': 'الفروع',
  'restaurant.users': 'المستخدمون',
  'restaurant.form.edit': 'تعديل المطعم',
  'branch.form.edit': 'تعديل الفرع',
  'branch.name': 'اسم الفرع',
  'branch.nameEn': 'اسم الفرع (إنجليزي)',
  'branch.nameAr': 'اسم الفرع (عربي)',
  'branch.location': 'الموقع',
  'user.branches': 'الفروع',
  'widgets.view.branches': 'فروع المطعم',
  'widgets.view.users': 'مستخدمو المطعم',
  'widgets.view.branches.users': 'مستخدمو الفرع',
  'widgets.view.suppliers': 'مورّدي المطعم',
  'widgets.view.kpi': 'عرض مؤشر قياس الأداء',
  'widgets.catalog': 'المنتجات',
  'widgets.merge': 'دمج المورّد',
  'notification.success.merge': 'تم اختيار المورّد بنجاح',
  'widgets.reject': 'رفض الدعوة',
  'user.type': 'التصنيف',
  'invitation.invitedBY': 'مدعو من قبل',
  'invitation.status': 'الحالة',
  'invitation.invoice': 'الفاتورة',
  'invitation.catalog': 'المنتجات',
  'unit.nameEn': 'اسم الوحدة (انكليزي)',
  'unit.nameAr': 'اسم الوحدة (عربي)',
  'dialog.unit.delete': 'حزف الوحدة',
  'dialog.message.unit.delete': 'هل أنت متأكد من حزف هذه الوحدة؟',
  'button.deleteButton': 'حزف',
  'unit.button.new': 'إنشاء الوحدة',
  'unit.button.edit': 'تعديل الوحدة',
  'unit.form.new.title': 'إنشاء وحدة',
  'unit.form.edit.title': 'تعديل وحدة',
  'global.success.message.catalog': 'تم تعديل المنتجات بنجاح',
  'global.success.message.saveAndNotify': 'تمت الموافقة على الدعوة بنجاح',
  'catalog.name': 'الاسم',
  'catalog.nameAr': 'الاسم (عربي)',
  'catalog.nameEn': 'الاسم (انكليزي)',
  'catalog.category': 'التصنيف',
  'catalog.code': 'الرمز',
  'catalog.defaultPrice': 'السعر',
  'catalog.defaultUnit': 'الوحدة',
  'catalog.specs': 'المواصفات',
  'catalog.status': 'الحالة',
  'catalog.synonyms': 'المرادفات',
  'approve.notify': 'موافقة و إعلام',
  'reject.invitation': 'رفض الدعوة',
  'product.add': 'إضافة منتج',
  'product.edit': 'تعديل منتج',
  'error.message.notify.rejected': 'لا يمكنك تاكيد هذه الدعوة لانها مرفوضة مسبقا',
  'error.message.notify.approved': 'لا يمكنك تاكيد هذه الدعوة لانها مقبولة مسبقا',
  'error.message.notify.company': 'يجب إضافة معلومات المورّد اللازمة لتأكيد الدعوة',
  'error.message.notify.employee': 'يجب إضافة معلومات المستخدم اللازمة لتأكيد الدعوة',
  'error.message.reject.rejected': 'لا يمكنك رفض هذه الدعوة لانها مرفوضة مسبقا',
  'error.message.reject.approved': 'لا يمكنك رفض هذه الدعوة لانها مقبولة مسبقا',
  'dialog.invitation.reject.pending': 'رفض الدعوة!',
  'dialog.message.invitation.reject.pending': 'هل أنت متأكد من رفض الدعوة؟',
  'dialog.extraInfo.invitation.reject.pending': '.لن تتمكن من تعديل حالة الدعوة مرة أُخرى',
  'dialog.invitation.approve.pending': 'الموافقة على الدعوة!',
  'dialog.message.invitation.approve.pending': 'هل أنت متأكد من الموافقة على الدعوة؟',
  'dialog.extraInfo.invitation.approve.pending': '.لن تتمكن من تعديل حالة الدعوة مرة أُخرى',
  'button.rejectInvitation': 'رفض',
  'button.approveInvitation': 'تأكيد',
  'button.attach': 'إضافة',
  'dialog.product.delete': 'حزف المنتج',
  'dialog.message.product.delete': 'هل أنت متأكد من حزف المنتج؟',
  'upload.file': 'رفع الملف',
  'notification.success.upload': 'تم رفع الملف بنجاح',
  'notification.error.upload.105':
    'ملف غير صالح: يوجد خطأ ما في البيانات تستطيع التاكد من بنية البيانات الصحيحة من خلال تحميل الملف التجريبي',
  'notification.error.upload.852': 'ملف غير صالح: ترميز المنتج مطلوب لتمام عملية رفع الملف بنجاح',
  'notification.error.upload.854': 'ملف غير صالح: الوحدة مطلوبة لتمام عملية رفع الملف بنجاح',
  'notification.error.upload.855': 'ملف غير صالح: اسم المنتج العربي او الانكليزي مطلوب لتمام عملية رفع الملف بنجاح',
  'notification.error.upload.851': 'ملف غير صالح! هناك رمز مكرر!',
  'notification.success.restaurant.supplier.detach': 'تم الغاء ارتباط المورّد بنجاح',
  'notification.success.restaurant.supplier.attach': 'تم إضافة المورّد بنجاح',
  'notification.success.delete.product': 'تم حزف المنتجات بنجاح',
  'notification.error.delete.product':
    'عذراً...احد المنتجات المراد المختارة للحذف لا يمكن حزفها بس تاثيرها على النظام يمكنك الغاء تفعيلها كحل بديل',
  'catalog.title': 'إدارة المنتجات',
  'catalog.brand': 'العلامة التجارية',
  'catalog.brandEn': 'العلامة التجارية (إنجليزي)',
  'catalog.brandAr': 'العلامة التجارية (عربي)',
  'edit.map': 'تعديل الموقع؟',
  'download.demo': 'تحميل ملف تجريبي؟',
  'download.csv': 'تحميل المنتجات كملف CSV',
  userInfo: 'معلومات المستخدم',
  'userInfo.name': 'الاسم',
  'userInfo.email': 'البريد الإلكتروني',
  'userInfo.phoneNumber': 'رقم الهاتف',
  'error.message.catalog.form': 'اسم المنتج مطلوب!, يجب إدخال الاسم إما بالعربي او بالانكليزي او كلاهما',
  note: 'ملاحظة:',
  'note.body':
    'يجب الانتباه ان الحقول (الاسم الانكليزي او الاسم العربي, الوحدة, رمز المنتج) مطلوبين لتمام عملية رفع الملف بنجاح.',
  'note.body2': 'يجب الانتباه ان الحقول (الاسم الانكليزي او الاسم العربي, الوحدة) مطلوبين لتمام عملية رفع الملف بنجاح.',

  ///////////////////

  'global.actions.viewOrders': 'View Orders',
  'global.actions.viewLocations': 'عرض المواقع',

  'global.errors.phoneNumber.notValid': 'رقم الهاتف غير صالح',
  'global.errors.not_found': 'البريد الإلكتروني غير موجود',

  'filters.isFeatured.true': 'نعم',
  'filters.isFeatured.false': 'لا',
  'global.filter.noMatch': 'لا يوجد نتائج متطابقة!!',

  'user.prefrences': 'خيارات المستخدم',
  'user.prefrences.primary': 'اللون الأولي',
  'user.prefrences.secondary': 'اللون الثانوي',
  // ------------------     Suppliers     ---------------------------
  supplier_admin: 'مالك الحساب',
  supplier_manager: 'مدير الحساب',
  'supplier.form.edit': 'تعديل المورّد',
  'supplier.form.add': 'إضافة مورّد',
  'supplier.error.isPublic': 'لا تستطيع تعديل هذا المورّد إلى غير عام لأن لديه عرض فعال!',
  'company.form.edit': 'تعديل معلومات المورّد',
  supplierName: 'اسم المورّد',
  'supplier.name': 'اسم المورّد',
  'company.name': 'اسم المورّد',
  'supplier.user.name': 'اسم المستخدم',
  'supplier.user.email': 'البريد الالكتروني للمستخدم',
  'supplier.user.phoneNumber': 'رقم الهاتف للمستخدم',
  'supplier.invitedBy': 'تمت الدعوة من قبل',
  'supplier.email': 'البريد الالكتروني للمورّد',
  'supplier.location': 'موقع المورّد',
  'supplier.merge': 'دمج المورّد',
  'supplier.tags': 'الكلمات المفتاحية',
  'supplier.catalog': 'منتجات المورّد',
  'supplier.upload': 'تحميل منتجات المورّد من خلال الملف',
  'supplier.info': 'معلومات المورّد',
  'suppliers.categories': 'الفئات',
  'suppliers.minimumOrder': 'الحد الأدنى للطلب',
  'suppliers.onBoardId': 'رقم التسجيل',
  'suppliers.onboardIdIsUsed': 'رقم التسجيل مستخدم',
  'suppliers.onboardIdIsInvalid': 'رقم التسجيل غير صحيح',
  'suppliers.averageDeliveryDays': 'متوسط أيام التسليم',
  'suppliers.days': 'يوم',
  'suppliers.cutOffTime': 'وقت انتهاء خدمة التوصيل',
  'suppliers.cutOffTime.hint': 'التوقيت سيكون حسب التوقيت العالمي!',
  'contact.person': 'معلومات الشخص المسؤول',
  'employee.name': 'اسم المستخدم',
  'employee.form.add': 'إضافة بريد الكتروني',
  'approve.invitation': 'تأكيد الدعوة',
  'supplier.view.products': 'عرض المنتجات',
  'supplier.view.sam_mapping': 'ربط المندوبين',
  'supplier.view.service.region.mapping': 'خريطة مناطق الخدمة',
  'supplier.view.service.region.configuration': 'اعدادات مناطق الخدمة',
  'supplier.view.processing_time': 'أوقات العمل',
  'supplier.users': 'المستخدمون',
  'restaurant.supplier.detach': 'الغاء ارتباط المورّد',
  'supplier.view.employees': 'المستخدمين',
  'supplier.type': 'النوع',
  'supplier.supplier_admin': 'أدمن',
  'dialog.suppliers.merge': 'دمج مستخدم مع مورّد!',
  'button.merge': 'دمج',
  'button.merge.yes': 'نعمو، دمج',
  'suppliers.error.merge.country': 'يجب أن يكون المستخدم المدموج بنفس بلد المورّد!',
  'dialog.message.suppliers.merge': 'هل أنت متأكد أنك تريد دمج ({employee}) مع ({supplierComp}) المورّد ؟',
  'suppliers.merger.success': 'تم دمج المستخدم مع المورّد بنجاح',
  'dialog.mergeSupplier.note':
    'عملية الدمج سوف توحد كل معلومات المستخدم مع المورّد(المنتجات، العروض، وقت انتهاء خدمة التوصيل، الحد الأدنى للطب والخصوصية)!',
  'suppliers.landLineNumber': 'الرقم الأرضي',
  'suppliers.invalid.number': 'الرقم غير صالح!',
  'userInfo.landLineNumber': 'الرقم الأرضي',
  'supplier.isActive': 'مفعل',

  // ------------------     Breadcrumb    ---------------------------
  'edit.breadcrumb': 'تعديل',
  'add.breadcrumb': 'إضافة',
  'activate.breadcrumb': 'تفعيل التنقل بين الصفحات',
  'users.breadcrumb': 'المستخدمون',
  'employees.breadcrumb': 'المستخدمين',
  'products.breadcrumb': 'المنتجات',
  'branch.breadcrumb': 'الفرع',
  'approve.breadcrumb': 'معالجة',
  'supplier.breadcrumb': 'المورّدين',
  'view.breadcrumb': 'عرض',
  'kpi.breadcrumb': 'مؤشر قياس الأداء',
  'managerEmployees.breadcrumb': 'مستخدمو الفرع',
  'breadcrumb.branches': 'الفروع',
  'breadcrumb.benchmarking': 'المرجعية',
  'merge.breadcrumb': 'دمج',
  'branches.breadcrumb': 'الفروع',
  'orders-list.breadcrumb': 'قائمة الطلبات',
  'details.breadcrumb': 'تفاصيل الطلب',
  'restaurants.details.breadcrumb': 'تفاصيل المطعم',
  'breadcrumb.countries': 'البلدان',
  'title.invitationRequests': 'طلبات الدعوة',
  'breadcrumb.profile': 'الحساب الشخصي',
  'breadcrumb.userManagement': 'إدارةالمستخدم',
  'breadcrumb.user': 'المستخدمون',
  'breadcrumb.employee': 'المستخدمين',
  'breadcrumb.restaurants': 'المطاعم',
  'breadcrumb.suppliers': 'المورّدين',
  'breadcrumb.products': 'المنتجات',
  'breadcrumb.elkasoUsers': 'مستخدمو التطبيق',
  'breadcrumb.invitationRequests': 'الدعوات',
  'breadcrumb.orders': 'الطلبات',
  'breadcrumb.units': 'الوحدات',
  'suppliers.breadcrumb': 'الموّردين',
  'breadcrumb.branch': 'الفروع',
  //-----------------orders --------------------------
  'orders.resend': 'إعادة إرسال بريد إلكتروني',
  'orders.resend.success': 'تمت إعادة إرسال البريد الإلكتروني بنجاح',
  'orders.lpo.download': 'تحميل ملف LPO',
  'orders.supplier': 'المورّد',
  'orders.restaurant': 'المطعم',
  'orders.items': 'موادالطلب',
  'orders.status': 'الحالة',
  'orders.ASAP': 'بأقرب وقت',
  'orders.note': 'ملاحظات',
  'orders.deliveryDate': 'تاريخ التسليم',
  'orders.fullNote': 'كامل الملاحظة',
  'item.name': 'المادة',
  'item.name.en': 'اسم المنتج (En)',
  'item.name.ar': 'اسم المنتج (Ar)',
  'item.price': 'السعر',
  'item.quantity': 'الكمية',
  'item.receivedQuantity': 'الكمية المستلمة',
  'item.receivedPrice': 'السعر المستلم',
  'item.unit': 'الوحدة',
  'orders.popover.orderItems': 'المواد المطلوبة',
  'orders.popover.restaurant': 'فروع المطعم',
  'orders.popover.supplierUser': 'مستخدم المورّد',
  'orders.view.employees': 'عرض مستخدمو المورّد',
  'orders.view.branches': 'عرض فروع المطعم',
  'orders.asap.true': 'نعم',
  'orders.asap.false': 'لا',
  'orders.comments': 'الملاحظات',
  'orders.comment': 'الملاحظة',
  'comment.add': 'إضافة ملاحظة',
  'comment.edit': 'تعديل الملاحظة',
  'orders.creationDate': 'تاريخ الإنشاء',
  'orders.cutOffTime': 'وقت انتهاء خدمة التوصيل',
  'orders.details': 'تفاصيل الطلب',
  'orders.details.order': 'الطلب #{code}',
  'orders.invoiceNumber': 'رقم الفاتورة',
  'orders.noInvoiceNumber': 'لا يوجد رفم للفاتورة',
  'orders.details.restaurant': 'المطعم',
  'orders.details.supplier': 'مزود الخدمة',
  'orders.details.delivery': 'تفاصيل التوصيل',
  'orders.details.deliveryTo': 'التوصيل إلى',
  'orders.details.deliveryTime': 'وقت التوصيل المتوقع',
  'orders.details.deliveryInstruction': 'تعليمات التوصيل',
  'orders.details.noInstruction': 'لا يوجد تعليمات للتوصيل',
  'orders.from': 'من {user}',
  'orders.details.items': 'مواد الطلب',
  'item.priceInv': 'سعر القطعة',
  'item.priceTot': 'الإجمالي',
  'comment added': 'تم إضافة تعليق',
  'comment updated': 'تم تحديث تعليق',
  'internal note added': 'تم إضافة ملاحظة',
  'internal note updated': 'تم تحديث الملاحظة',
  'invoice number added': 'تم إضافة رقم فاتورة',
  'invoice number updated': 'تم تحديث رقم فاتورة',
  'delivery date changed': 'تم تعديل وقت التسليم',
  'order updated': 'تم تعديل الطلب',
  'invoice image added': 'تم إضافة صورة الفاتورة',
  'invoice image updated': 'تم تحديث صورة الفاتورة',
  'global.errors.code.unique': 'عذراً ترميز المنتج موجود مسبقاً',
  'global.errors.code.file.unique': 'عذراً ترميز المنتجات ضمن الملف يجب ان تكون مميزة',
  'view.restaurant': 'عرض المطعم',
  'view.company': 'عرض المورّد',
  'view.restaurant.supplier': 'المطعم/المورّد',
  'products.key': 'اسم المنتج',
  'products.count': 'العدد',
  'products.alternatives': 'المنتجات البديلة',
  'merge.company.error': 'الرجاء اختيار المورّد',
  'please.provide.this.employee.email': 'الرجاء إضافة البريد الالكتروني للمستخدم',
  'error.upload': 'لم يتم اختيار ملف للرفع',
  'invitation.summary': 'ملخص الدعوة',
  'invitation.summary.company': 'معلومات المورّد',
  'invitation.summary.employee': 'معلومات الشخص المسؤول',
  'invitation.wizard': 'معالجة',
  'button.next': 'التالي',
  'button.back': 'رجوع',
  'button.approve.invitation': 'تأكيد الدعوة',
  'is.this.an.employee.for': 'هل هو مستخدم لـ',
  optional: 'اختياري',
  'company.info': 'معلومات المورّد',
  'person.in.contact.info': 'معلومات الشخص المسؤول',
  'upload.catalog': 'رفع المنتجات',
  'new.company': 'مورّد جديد',
  'merge.with.an.existing.company': 'اختيار مورّد موجود مسبقا',
  'is.public': 'مورّد عام',
  'is.highlighted': 'مميز ؟',
  'add.company': 'إضافة مورّد',
  'employee.info': 'معلومات المستخدم',
  'create.company': 'إنشاء المورّد',
  'apply.changes': 'إجراء التغييرات',
  supplier_user: 'مستخدم المورّد',
  restaurant_admin: 'مالك المطعم',
  'filters.type.supplier_manager': 'مدير الحساب',
  'filters.type.supplier_user': 'مستخدم المورّد',
  'filters.type.restaurant_admin': 'مالك المطعم',
  'filters.type.restaurant_manager': 'مدير المطعم',
  'filters.type.restaurant_user': 'مستخدم المطعم',
  'filters.status.draft': 'مسودة',
  draft: 'مسودة',
  'filters.status.approved': 'تمت الموافقة',
  approved: 'تمت الموافقة',
  'filters.status.restaurant_placed': 'طلب جديد',
  canceled: 'تم إلغاء الطلب',
  restaurant_placed: 'طلب جديد',
  draft_expire: 'طلب منتهي الصلاحية',
  'filters.status.draft_expire': 'طلب منتهي الصلاحية',
  'filters.status.supplier_placed': 'تم تقديم الطلب(مورّد)',
  supplier_placed: 'تم تقديم الطلب(مورّد)',
  'filters.status.received': 'تم استلامه',
  rejected: 'تم رفضه',
  received: 'تم استلامه',
  'filters.status.restaurant_placed_approval': 'بحاجة إلى موافقة',
  restaurant_placed_approval: 'بحاجة إلى موافقة',
  'filters.isPublic.true': 'نعم',
  'filters.isPublic.false': 'لا',
  'filters.isHighlighted.true': 'نعم',
  'filters.isHighlighted.false': 'لا',
  true: 'نعم',
  false: 'لا',
  'filters.status.rejected': 'تم رفضه',
  'filters.status.inactive': 'غير مفعّل',
  'filters.status.null': 'غير مفعّل',
  'filters.status.suggested': 'مقترح',
  'upload.component.text': 'اسحب وادرج ملف بصيغة CSV او اضغط لاختيار الملف.... يسمح فقط باختيار الملف ذو الصيغة CSV.',
  'orders.code': 'الرمز',
  'get.master.key': 'الحصول على المفتاح الرئيسي',
  'enter.password': 'ادخل كلمة السر: ',
  'master.key': 'المفتاح الرئيسي: ',
  'wrong.password': 'كلمة سر خاطئة',
  'order.created': 'إنشاء الطلب',
  'order.updated': 'تعديل الطلب',
  'order.placed': 'تم تقديم الطلب',
  'order.approved': 'تمت الموافقة على الطلب',
  'order.received': 'تم استلامه',
  'order.canceled': ' تم إلغاء الطلب',
  'order.rejected': ' تم رفض الطلب',
  'restaurant.user.delete.error.810': 'عذرا.. لا يمكنك حزف هذا المستخدم لوجود طلبات متعلقة به!!',
  'statistics.restaurantUsers': 'مستخدمو المطاعم',
  'statistics.restaurantUsers.help': 'عدد مستخدمو المطاعم',
  'statistics.suppliersUsers': 'مستخدمو المورّدين',
  'statistics.suppliersUsers.help': 'عدد مستخدمو المورّدين',
  'statistics.placedOrders': 'الطلبات الموضوعة',
  'statistics.placedOrders.help': 'عدد الطلبات التي تم وضعها',
  'statistics.approvedOrders': 'الطلبات المؤكدة',
  'statistics.approvedOrders.help': 'عدد الطلبات المؤكدة',
  'statistics.avgBasketSize': 'معدل عدد منتجات الطلبات',
  'statistics.avgBasketSize.help': 'معدل عدد منتجات الطلبات',
  'statistics.avgSpendingsPerOrder': 'معدل اجمالي كل طلب',
  'statistics.avgSpendingsPerOrder.help': 'معدل اجمالي كل طلب',
  'statistics.totalProducts': 'عدد المنتجات الكلي',
  'statistics.totalProducts.help': 'عدد المنتجات الكلي',
  'kpi.requestedOrders': 'الطلبات المطلوبة',
  'kpi.requestedOrders.help': 'عدد الطلبات التي تم طلبها',
  'kpi.approvedOrders': 'الطلبات المؤكدة',
  'kpi.approvedOrders.help': 'عدد الطلبات التي تم تأكيدها',
  'kpi.canceledOrders': 'الطلبات الملغاة',
  'kpi.canceledOrders.help': 'عدد الطلبات التي تم الغاءها',
  'kpi.approvedOrdersValue': 'إجمالي المدفوعات',
  'kpi.approvedOrdersValue.help': 'إجمالي قيمة الطلبات المؤكدة',
  'kpi.avgBasketSize': 'معدل حجم السلة',
  'kpi.avgBasketSize.help': 'معدل عدد المنتجات في كل طلب',
  'kpi.avgSpendingsPerOrder': 'معدل قيمة الطلب',
  'kpi.avgSpendingsPerOrder.help': 'معدل قيمة الطلب',
  'kpi.lastOrderDate': 'تاريخ أحدث طلب',
  'kpi.lastOrderDate.help': 'تاريخ أحدث طلب',
  'kpi.restaurantsCount': 'عدد المطاعم',
  'kpi.restaurantsCount.help': 'عدد المطاعم التي تم التعامل معها',
  'kpi.avgDeliveryTime': 'معدل وقت التوصيل',
  'kpi.avgDeliveryTime.help': 'معدل وقت التوصيل مقاس بالأيام',
  'kpi.totalProducts': 'عدد المنتجات',
  'kpi.totalProducts.help': 'العدد الكلي للمنتجات الفعالة',
  'kpi.restaurant': 'مؤشر قياس أداء المطعم',
  'kpi.supplier': 'مؤشر قياس أداء المورّد',
  'dialog.attachSupplier.note': 'ملاحظة: سوف يتم عرض المورّدين المتواجدون في نفس بلد المطعم',
  'statistics.date': 'التاريخ',
  'statistics.startDate': 'تاريخ البداية',
  'statistics.endDate': 'تاريخ النهاية',
  'kpi.date.error': 'تاريخ البداية يجب أن يكون الأصغر!',
  'kpi.endDate.error': 'تاريخ النهاية يجب أن يكون الأكبر!',

  //----------------- Promotions -------------------------
  'breadcrumb.promotions': 'العروض',
  'promotions.form.new.title': 'إضافة عرض',
  'promotions.form.edit.title': 'تعديل عرض',
  'promotions.form.new': 'إضافة عرض',
  'promotions.form.edit': 'تعديل عر ض',
  'promotions.summary': 'الملخص',
  'promotions.titleEn': 'العنوان (إنجليزي)',
  'promotions.titleAr': 'العنوان (عربي)',
  'promotions.descEn': 'الوصف (إنجليزي)',
  'promotions.descAr': 'الوصف (عربي)',
  'promotions.summaryEn': 'الملخص (إنجليزي)',
  'promotions.summaryAr': 'الملخص (عربي)',
  'promotions.supplier': 'المورّد',
  'promotions.expiryDate': 'تاريخ الانتهاء',
  'promotions.expired': 'العرض منتهي الصلاحية!',
  'dialog.promotions.info.expiryDate': 'لا يمكنك تفعيل هذا العرض حتى تقوم بتحديث تاريخ الصلاحية الخاص به!',
  'dialog.promotions.isActive.true': 'تفعيل العرض!',
  'dialog.message.promotions.isActive.true': 'هل أنت متأكد أنك تريد تفعيل هذا العرض؟',
  'dialog.promotions.isActive.false': 'إلغاء تفعيل العرض!',
  'dialog.message.promotions.isActive.false': 'هل أنت متأكد أنك تريد إلغاء تفعيل هذا العرض؟',
  'button.global.actions.update': 'تحديث',
  'promotions.IsActive.True': 'تحديث العرض!',
  'promotions.supplier.info': 'معلومات المورّد',
  'promotions.supplier.name': 'الاسم',
  'promotions.supplier.email': 'البريد الإلكتروني',
  'promotions.button.new': 'إضافة العرض',
  'promotions.button.edit': 'تعديل العرض',

  //----------------- Categories -------------------------
  'breadcrumb.categories': 'الفئات',
  'categories.form.new.title': 'إضافة الفئة',
  'categories.form.edit.title': 'تعديل الفئة',
  'categories.form.new': 'إضافة الفئة',
  'categories.form.edit': 'تعديل الفئة',
  'categories.title': 'اسم الفئة',
  'categories.titleEn': 'اسم الفئة (إنجليزي)',
  'categories.titleAr': 'اسم الفئة (عربي)',
  'dialog.categories.isActive.true': 'تفعيل فئة!',
  'dialog.message.categories.isActive.true': 'هل أنت متأكد أنك تريد تفعيل هذه الفئة؟',
  'dialog.categories.isActive.false': 'إلغاء تفعيل فئة!',
  'dialog.message.categories.isActive.false': 'هل أنت متأكد أنك تريد إلغاء تفعيل هذه الفئة؟',
  'categories.button.new': 'إضافة الفئة',
  'categories.button.edit': 'تعديل الفئة',
  'categories.error.deactivate': 'هذه الفئة مرتبطة بمورّد! لا يمكنك إلغاء تقعيلها!',

  //--------------------------- Preferences ----------------------------
  'permissions.restaurants': 'المطعم',
  'permissions.branches': 'الفرع',
  'permissions.clearFilters': 'حذف الفلاتر',
  'permissions.noData': 'لا يوجد بيانات للعرض',
  'permissions.viewDetails': 'للحصول على بيانات الرجاء تغيير أحد الفلاتر السابقة',
  'permissions.orders': 'يتطلب موافقة',
  'permissions.chats': 'رؤية الدردشات',
  'permissions.change.preference.success': 'تم تغيير التفضيل بنجاح',
  'permissions.type': 'التفضيل',
  'dialog.permissions.false': 'إلغاء تفعيل التفضيل!',
  'dialog.message.permissions.false': 'هل أنت متأكد أنك تريد إلغاء تفعيل هذا التفضيل؟',
  'dialog.permissions.true': 'تفعيل التفضيل!',
  'dialog.message.permissions.true': 'هل أنت متأكد أنك تريد تفعيل هذا التفضيل؟',
  'permissions.permissions.entityChats.false': 'لا',
  'permissions.permissions.entityChats.true': 'نعم',
  'permissions.adminRights': 'دعوة مستخدمين',
  'permissions.permissions.ordersApproval.false': 'لا',
  'permissions.permissions.ordersApproval.true': 'نعم',
  'permissions.isConfirmed': 'إظهار المتجر',
  'permissions.isConfirmed.false': 'لا',
  'permissions.isConfirmed.true': 'نعم',
  'dialog.permissions.isConfirmed.false': 'إخفاء المتجر!',
  'dialog.message.permissions.isConfirmed.false': 'هل أنت متأكد أنك تريد تغيير الحالة إلى مخفي؟',
  'dialog.permissions.isConfirmed.true': 'إظهار المتجر!',
  'dialog.message.permissions.isConfirmed.true': 'هل أنت متأكد أنك تريد تغيير الحالة إلى غير مخفي؟',
  'dialog.extraInfo.permissions.isConfirmed.false':
    'الرجاء الانتباه أن هذا المستخدم لن يكون قادر على إجراء أي طلب من أي موّرد عام مرتبط به!!',
  'dialog.extraInfo.permissions.isConfirmed.true':
    'الرجاء الانتباه أن هذا المستخدم سيكون قادر على إجراء أي طلب من من أي موّرد عام غير مرتبط به!!',
  'permissions.canEditCatalog': 'صلاحية تعديل الدليل',

  //------------------ OTP ----------------------
  'otp.phoneNumber': 'رقم الهاتف',
  'otp.reference': 'وسيلة التواصل',
  'otp.code': 'كود التفعيل',
  'otp.status': 'الحالة',
  'otp.pending': 'قيد الاستخدام',
  'otp.done': 'مُستخدم',
  'otp.pending.hint': 'المستخدم لم يستخدم كود التفعيل بعد!!',
  'otp.done.hint': 'المستخدم استخدم كود التفعيل',
  'otp.type': 'نوع الرسالة',
  'otp.type.sms': 'رسالة نصية',
  'otp.type.email': 'بريد الكتروني',
  'otp.type.whatsapp': 'واتساب',
  'otp.status.pending': 'قيد الانتظار',
  'otp.status.expired': 'منتهية الصلاحية',
  'otp.status.done': 'تم',

  //------------------ Whats Numbers ----------------------
  'whats.user': 'الموّرد',
  'whats.active': 'إرسال رسائل',
  'whats.phoneNumber': 'رقم الهاتف',
  'dialog.whats.isActive.false': 'إلغاء تفعيل إرسال رسائل واتس!',
  'dialog.message.whats.isActive.false': 'هل أنت متأكد أنك تريد إلغاء تفعيل إرسال رسائل واتس لهذا الرقم؟',
  'dialog.whats.isActive.true': 'تفعيل إرسال رسائل واتس!',
  'dialog.message.whats.isActive.true': 'هل أنت متأكد أنك تريد تفعيل إرسال رسائل واتس لهذا الرقم؟',

  //------------------  Countries ----------------------
  countries: 'البلدان',
  'countries.name': 'اسم البلد',
  'countries.location': 'الموقع',
  'countries.form.new.title': 'إضافة بلد',
  'countries.form.edit.title': 'تعديل البلد',
  'countries.form.view.title': 'عرض البلد',
  'countries.startTime': 'بداية الوقت المستقطع',
  'countries.endTime': 'نهاية الوقت المستقطع',
  'countries.key': 'تبدأ برقم',
  'countries.currency': 'العملة',
  'countries.currencyEn': 'العملة (إنجليزي)',
  'countries.currencyAr': 'العملة (عربي)',
  'countries.flag': 'علم البلد',
  'countries.location.required': 'الموقع إجباري!',
  'dialog.countries.isActive.false': 'إلعاء تفعيل البلد!',
  'dialog.countries.isActive.true': 'تفعيل البلد',
  'dialog.message.countries.isActive.false': 'هل أنت متأكد أنك تريد إلغاء تفعيل هئا البلد؟',
  'dialog.message.countries.isActive.true': 'هل أنت متأكد زنت تريد تفيعل هذا البلد؟',

  //------------------  Restaurant Transfers ----------------------
  'breadcrumb.restaurant-transfers': 'التحويلات مطعم',
  'transfer-details.breadcrumb': 'تفاصيل النقل',
};
