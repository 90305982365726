import { getValidatedPageParam, useCustomToast } from '@elkaso-app/web-design';
import http from 'apis/config/http-service';
import { useQueryWrapper } from 'apis/config/use-api-wrapper';
import type { AxiosError } from 'axios';
import { useIsLoggedIn } from 'hooks/use-is-logged-in';
import { UseQueryOptions, UseQueryResult } from 'react-query';
import { TParam } from '../../hooks/use-page-params';

const endpoint = 'ums/v1/analytics/restaurants/locations';
export const getRestaurantsLocationsCountsEndpointIdentifier = endpoint;

export interface RestaurantsLocationsCountsData {
  id: number;
  nameEn: string;
  countryCode: string;
  branchesCount: number;
  warehousesCount: number;
}

export interface RestaurantsLocationsCountsPaginatedProps {
  page: number;
  data: RestaurantsLocationsCountsData[];
  pageCount: number;
  total: number;
  count: number;
  hasNext: boolean;
  hasPrev: boolean;
}

type TData = RestaurantsLocationsCountsPaginatedProps;
type TError = AxiosError;
type TQueryKey = [
  typeof endpoint,
  {
    page: TParam;
    limit: TParam;
  }
];
type Options = Omit<UseQueryOptions<unknown, TError, TData, TQueryKey>, 'queryKey' | 'queryFn'>;
type ReturnType = UseQueryResult<TData, TError>;

export function useGetRestaurantsLocationsCountsApi(options?: Options): ReturnType {
  const toast = useCustomToast();
  const page = getValidatedPageParam();
  const limit = 15;
  const isLoggedIn = useIsLoggedIn();

  const queryFn = async () => {
    try {
      const { data } = await http.get(endpoint, {
        params: {
          page,
          limit,
        },
      });

      return data;
    } catch (error: any) {
      if (!error.response.status || !error.response.data.message) throw error;

      toast({
        description: error.response.data.message,
        status: 'error',
      });

      throw error;
    }
  };

  return useQueryWrapper(
    [
      endpoint,
      {
        page,
        limit,
      },
    ],
    queryFn,
    {
      enabled: isLoggedIn,
      ...options,
    }
  );
}
