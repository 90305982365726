import { StackProps, Text, VStack } from '@elkaso-app/web-design';
import { Link } from 'react-router-dom';
import { PhoneNumber } from '../../../components/base-table';

export interface ISupplierInfoCell extends StackProps {
  supplierId: string;
  name: string;
  supplierUserName: string;
  phoneNumber: string;
}

export const SupplierInfoCell = ({ supplierId, name, supplierUserName, phoneNumber, ...rest }: ISupplierInfoCell) => {
  return (
    <VStack spacing='sm' align='start' maxW='200px' {...rest}>
      <Link to={`/app/suppliers/list?id=${supplierId}`}>
        <Text
          whiteSpace='pre-line'
          color={'red.500'}
          fontWeight='500'
          _hover={{ color: 'red.500' }}
          _focus={{ color: 'red.500' }}>
          {name}
        </Text>
      </Link>

      <Text whiteSpace='pre-line' fontSize='md' color='gray.500'>
        {supplierUserName}
      </Text>

      <PhoneNumber value={phoneNumber} />
    </VStack>
  );
};
