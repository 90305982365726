import { Layout, Section } from 'layout';
import { UniqueProductsListScreen } from 'pages/products/screens/unique-products-list-screen';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

const ProductsPage = () => {
  const match = useRouteMatch();

  return (
    <Layout type={'sidebar_page_layout'}>
      <Section name={'content'}>
        <Switch>
          <Redirect exact from={`${match.url}/`} to={`${match.url}/unique-list`} />
          <Route path={`${match.url}/unique-list`} component={UniqueProductsListScreen} />
          <Route path='*' render={() => <Redirect to='/404' />} />
        </Switch>
      </Section>
    </Layout>
  );
};

export default ProductsPage;
