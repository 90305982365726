import { HStack, Icon, Text, Tooltip } from '@elkaso-app/web-design';
import { FaWhatsapp } from 'react-icons/fa6';
import { IoCheckmarkSharp, IoCopyOutline } from 'react-icons/io5';
import { useClipboard } from '../../../hooks/use-clipboard';
import { onWhatsAppClick } from '../../../utils/on-whatsapp-click';

export interface PhoneNumberProps {
  value: string;
}

export const PhoneNumber = ({ value }: PhoneNumberProps) => {
  const { onCopy, hasCopied } = useClipboard();

  return (
    <HStack spacing='xs' align='center' position='relative'>
      <Text fontSize='md' color='gray.500'>
        {value}
      </Text>

      <Tooltip label='WhatsApp' aria-label='whatsapp' placement='bottom' hasArrow>
        <span>
          <Icon as={FaWhatsapp} fill='whatsapp.500' cursor='pointer' onClick={() => onWhatsAppClick(value)} />
        </span>
      </Tooltip>

      <Tooltip label='Copy' aria-label='copy' placement='bottom' hasArrow>
        <span>
          {!hasCopied && <Icon as={IoCopyOutline} fill='gray.500' cursor='pointer' onClick={() => onCopy(value)} />}

          {hasCopied && <Icon as={IoCheckmarkSharp} fill='gray.500' cursor='pointer' />}
        </span>
      </Tooltip>
    </HStack>
  );
};
