import { HStack, VStack, WhiteCard } from '@elkaso-app/web-design';
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import React, { useEffect } from 'react';
import { changeTitle } from 'redux/actions';
import { useAppDispatch } from 'redux/hooks';
import {
  SuppliersOrdersMonthlyCountsData,
  useGetSuppliersOrdersMonthlyCountsApi,
} from '../../../apis/analytics/use-get-supplier-orders-monthly-counts-api';
import { SuppliersOrdersMonthlyCountBarChart } from '../components/suppliers-orders-count-bar-chart';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  PointElement,
  LineElement,
  Title,
  LineController,
  Tooltip,
  Legend
);

export const AnalyticsSupplierDashboardScreen = () => {
  const dispatch = useAppDispatch();
  const { data: suppliersOrdersMonthlyCounts } = useGetSuppliersOrdersMonthlyCountsApi();

  useEffect(() => {
    dispatch(changeTitle('sidebar.analytics'));
  }, []);

  interface OrdersChartDataProps {
    suppliersOrdersData: SuppliersOrdersMonthlyCountsData[];
  }

  const ordersChartDataProps = React.useMemo((): OrdersChartDataProps => {
    return {
      suppliersOrdersData: (suppliersOrdersMonthlyCounts || []) as SuppliersOrdersMonthlyCountsData[],
    };
  }, [suppliersOrdersMonthlyCounts]);

  return (
    <HStack maxWidth={'full'} overflow={'hidden'} spacing='md' align='stretch' h='full'>
      <WhiteCard display={'flex'} flexDirection={'column'} width={'full'} overflow={'auto'}>
        <VStack padding={3} align='normal' h='full'>
          <HStack justify='space-between'>
            <SuppliersOrdersMonthlyCountBarChart
              data={ordersChartDataProps.suppliersOrdersData}
              title={'Suppliers External Orders Monthly Activity stacked by countries'}
            />
          </HStack>
        </VStack>
      </WhiteCard>
    </HStack>
  );
};
