import {
  Box,
  Button,
  Center,
  ChakraSelect,
  FormControl,
  FormErrorMessage,
  Highlight,
  HStack,
  IconButtonWithTooltip,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  StackDivider,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@elkaso-app/web-design';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEditProcessingDaysApi } from 'apis/agg-sheets-relations/use-edit-processing-days-api';
import { useGetProcessingDaysByIdApi } from 'apis/agg-sheets-relations/use-get-processing-days-by-id-api';
import { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { HiOutlineCalendar } from 'react-icons/hi';
import { MdChecklistRtl } from 'react-icons/md';
import * as yup from 'yup';
import { AggSheetRelation, SubEntityType, WeekdaysLabel, WeekdaysValue } from '../types';

interface ApplyToAllModalProps {
  fieldIndex: number;
  subEntityName: string;
  callBack: (fieldIndex: number) => void;
}

const ApplyToAllModal = ({ fieldIndex, subEntityName, callBack }: ApplyToAllModalProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const onSave = () => {
    callBack(fieldIndex);
    onClose();
  };

  return (
    <>
      <IconButtonWithTooltip
        Icon={<MdChecklistRtl size='24px' />}
        label='Apply for all'
        aria-label='apply-for-all'
        variant='ghost'
        size='sm'
        onClick={onOpen}
      />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Apply To All</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Highlight query={[subEntityName]} styles={{ fontWeight: 'bold' }}>
              {`Are you sure you want to apply all the weekdays settings for ${subEntityName} to all other branches?`}
            </Highlight>
          </ModalBody>

          <ModalFooter>
            <HStack spacing='md'>
              <Button variant='outline' colorScheme='red' onClick={onClose}>
                Cancel
              </Button>

              <Button variant='solid' colorScheme='red' onClick={onSave}>
                Save
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

type TOption = {
  label: WeekdaysLabel;
  value: WeekdaysValue;
};

const weekdaysOptions: TOption[] = [
  {
    label: 'Sun',
    value: 0,
  },
  {
    label: 'Mon',
    value: 1,
  },
  {
    label: 'Tue',
    value: 2,
  },
  {
    label: 'Wed',
    value: 3,
  },
  {
    label: 'Thu',
    value: 4,
  },
  {
    label: 'Fri',
    value: 5,
  },
  {
    label: 'Sat',
    value: 6,
  },
];

type TInputs = {
  processingDays: {
    subEntityId: number;
    subEntityType: SubEntityType;
    weekdays: TOption[];
  }[];
};

const defaultValues: TInputs = {
  processingDays: [],
};

const schema = yup.object().shape({
  processingDays: yup.array().of(
    yup.object().shape({
      subEntityId: yup.number().required(),
      subEntityType: yup.string().required(),
      weekdays: yup.array().of(
        yup.object().shape({
          label: yup
            .string()
            .oneOf(['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'])
            .required('Weekday label is required'),
          value: yup.number().oneOf([0, 1, 2, 3, 4, 5, 6]).required('Weekday value is required'),
        })
      ),
    })
  ),
});

interface IEditProcessingDaysModal {
  aggSheet: AggSheetRelation;
}

export const EditProcessingDaysModal = ({ aggSheet }: IEditProcessingDaysModal) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    refetch: refetchProcessingDaysByIdApi,
    data: processingDaysData,
    isLoading: isLoadingProcessingDaysByIdApi,
    isFetching: isFetchingProcessingDaysByIdApi,
  } = useGetProcessingDaysByIdApi(aggSheet.id);

  const isLoading = isLoadingProcessingDaysByIdApi || isFetchingProcessingDaysByIdApi;

  const { isLoading: isLoadingEditProcessingDays, mutate: editProcessingDaysApi } = useEditProcessingDaysApi();

  const { handleSubmit, reset, control, watch } = useForm<TInputs>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (isOpen) {
      refetchProcessingDaysByIdApi();
    }
  }, [isOpen]);

  const resetToDefaultValues = () => {
    if (processingDaysData) {
      const defaultProcessingDays = processingDaysData?.map((item) => ({
        subEntityId: item?.id,
        subEntityType: item?.type,
        weekdays: item?.weekdays?.map((i) => {
          return weekdaysOptions?.find((o) => o.value === i);
        }),
      }));

      reset({ processingDays: defaultProcessingDays });
    }
  };

  useEffect(() => {
    resetToDefaultValues();
  }, [processingDaysData]);

  const processingDays = watch('processingDays');

  const applyWeekdaysToAll = (index: number) => {
    const selectedWeekdays = processingDays?.[index]?.weekdays;

    const updatedProcessingDays = processingDays?.map((day) => ({
      ...day,
      weekdays: selectedWeekdays,
    }));

    reset({ processingDays: updatedProcessingDays });
  };

  const onSubmit: SubmitHandler<TInputs> = (data) => {
    const variables = {
      aggSheetId: aggSheet?.id,
      body: {
        processingDays: data?.processingDays?.map((i) => {
          return {
            ...i,
            weekdays: i?.weekdays?.map((d) => d.value),
          };
        }),
      },
    };

    editProcessingDaysApi(variables, {
      onSuccess() {
        onClose();
      },
    });
  };

  return (
    <Box>
      <IconButtonWithTooltip
        size='sm'
        label='Edit Processing Days'
        aria-label='edit-processing-days-btn'
        Icon={<HiOutlineCalendar size='22px' />}
        variant='unstyled'
        onClick={onOpen}
      />

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        onCloseComplete={resetToDefaultValues}
        size='6xl'
        scrollBehavior='inside'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Processing Days</ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            {!!isLoading && (
              <Center p='lg'>
                <Spinner color='red.500' />
              </Center>
            )}

            {!isLoading && processingDaysData && (
              <form id='editProcessingDaysForm' onSubmit={handleSubmit(onSubmit)} noValidate>
                <TableContainer>
                  <Table variant='simple' size='sm'>
                    <Thead>
                      <Tr>
                        <Th>Sub-Entity</Th>
                        <Th>Processing Days</Th>
                        <Th>Actions</Th>
                      </Tr>
                    </Thead>

                    <Tbody>
                      {processingDaysData?.map((i, index) => (
                        <Tr key={i?.id}>
                          <Td>
                            <HStack align='start' divider={<StackDivider />}>
                              <Text color='black' fontWeight='semibold'>
                                {i?.name}
                              </Text>
                              <Text>{i?.type}</Text>
                            </HStack>
                          </Td>
                          <Td maxW='490px' minW='490px'>
                            <Controller
                              control={control}
                              name={`processingDays.${index}.weekdays` as const}
                              render={({
                                field: { name, value, onBlur, onChange },
                                fieldState: { error, invalid },
                              }) => (
                                <FormControl isInvalid={invalid}>
                                  <ChakraSelect
                                    options={weekdaysOptions}
                                    onChange={onChange}
                                    name={name}
                                    onBlur={onBlur}
                                    value={value}
                                    placeholder='Select processing days'
                                    isMulti
                                    closeMenuOnSelect={false}
                                    size='sm'
                                    menuPlacement={processingDaysData.length < index + 5 ? 'top' : 'bottom'}
                                  />
                                  <FormErrorMessage>{error?.message}</FormErrorMessage>
                                </FormControl>
                              )}
                            />
                          </Td>
                          <Td>
                            <ApplyToAllModal fieldIndex={index} subEntityName={i?.name} callBack={applyWeekdaysToAll} />
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                  {/* <Box mt={190} /> */}
                </TableContainer>
              </form>
            )}
          </ModalBody>

          <ModalFooter>
            <HStack spacing='md'>
              <Button variant='outline' colorScheme='red' onClick={onClose}>
                Cancel
              </Button>

              <Button
                variant='solid'
                colorScheme='red'
                type='submit'
                form='editProcessingDaysForm'
                isDisabled={isLoadingProcessingDaysByIdApi}
                isLoading={isLoadingEditProcessingDays}>
                Save
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};
