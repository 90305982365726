import { Image } from '@elkaso-app/web-design';
export interface ICategoryImageCell {
  image: string | null;
}

export const CategoryImageCell = ({ image }: ICategoryImageCell) => {
  return (
    <Image
      borderRadius='10px'
      boxSize='50px'
      objectFit='cover'
      src={image ?? require('assets/img/ph.svg').default}
      alt='image'
    />
  );
};
