import { HStack, IconButtonWithTooltip } from '@elkaso-app/web-design';
import { PosIntegrationData } from 'apis/pos-integrations/use-get-pos-integrations-api';
import { MdLogin, MdOutlineWarningAmber } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { LocalStorageKeys } from 'utils/constants';

export interface ActionsCellData {
  integration: PosIntegrationData;
}

export const ActionsCell = ({ integration }: ActionsCellData) => {
  const history = useHistory();

  const onIssuesClick = () => {
    history.push({
      pathname: '/app/pos-integrations/issues',
      search: `restaurantName=${integration?.restaurant?.nameEn}`,
    });
  };

  const constructUrl = (redirect: string) => {
    const accessToken = localStorage.getItem(LocalStorageKeys.access_token);
    const params = `access_token=${accessToken}&phone_number=${integration?.user?.phoneNumber}&redirect=${redirect}`;
    return `${process.env.REACT_APP_BASE_URL}/ums/v1/auth/support/login?${params}`;
  };

  const onSupportLoginClick = () => {
    window.open(constructUrl('inventory_app'));
  };

  return (
    <HStack>
      <IconButtonWithTooltip
        Icon={<MdOutlineWarningAmber fontSize='20px' color='gray' />}
        label='Issues'
        aria-label='Issues'
        size='xs'
        variant='unstyled'
        onClick={onIssuesClick}
      />

      <IconButtonWithTooltip
        Icon={<MdLogin fontSize='20px' color='gray' />}
        label='Support login'
        aria-label='support login'
        size='xs'
        variant='unstyled'
        onClick={onSupportLoginClick}
      />
    </HStack>
  );
};
