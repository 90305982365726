import AccessDeniedPage from 'pages/access-denied';
import { AggregatedSheets } from 'pages/aggregated-sheets';
import BenchmarkingPage from 'pages/benchmarking';
import CatalogPage from 'pages/catalog';
import CategoriesPage from 'pages/categories';
import CountriesPage from 'pages/countries';
import ElkasoUsersPage from 'pages/elkasoUsers';
import ExpiredSessionPage from 'pages/expired-session';
import ExpiredTokenPage from 'pages/expired-token';
import NotFoundPage from 'pages/general/not-found-page';
import LoginPage from 'pages/login';
import OrdersPage from 'pages/orders';
import PermissionsPage from 'pages/permissions';
import PosIntegrationsPage from 'pages/pos-integrations';
import ProductsPage from 'pages/products';
import ProfilePage from 'pages/profile';
import PromotionsPage from 'pages/promotions';
import RestaurantTransfersPage from 'pages/restaurant-transfers';
import RestaurantsPage from 'pages/restaurants';
import SmsOtpPage from 'pages/sms-otp';
import StatisticsPage from 'pages/statistics';
import SuppliersPage from 'pages/suppliers';
import TicketManagementPage from 'pages/ticket-management';
import UserManagementPage from 'pages/user-management';
import ValidationCodePage from 'pages/validation-code';
import WhatsNumbersPage from 'pages/whatsNumbers';
import { RouteProps } from 'react-router-dom';
import AnalyticsPage from '../pages/analytics';
// import { ability } from '../configs/ability';

// A.C: Routes after user logged in only
export const privateRoutes: RouteProps[] = [
  {
    path: '/app/user-management',
    component: UserManagementPage,
  },
  {
    path: '/app/benchmarking',
    component: BenchmarkingPage,
  },
  {
    path: '/app/Profile',
    component: ProfilePage,
  },
  {
    path: '/app/restaurants',
    component: RestaurantsPage,
  },
  {
    path: '/app/catalog',
    component: CatalogPage,
  },
  {
    path: '/app/suppliers',
    component: SuppliersPage,
  },
  {
    path: '/app/products',
    component: ProductsPage,
  },
  {
    path: '/app/agg-sheet-relations',
    component: AggregatedSheets,
  },
  {
    path: '/app/ticket',
    component: TicketManagementPage,
  },
  {
    path: '/app/elkasoUsers',
    component: ElkasoUsersPage,
  },
  {
    path: '/app/orders',
    component: OrdersPage,
  },
  {
    path: '/app/restaurant-transfers',
    component: RestaurantTransfersPage,
  },
  {
    path: '/app/pos-integrations',
    component: PosIntegrationsPage,
  },
  {
    path: '/app/statistics',
    component: StatisticsPage,
  },
  {
    path: '/app/promotions',
    component: PromotionsPage,
  },
  {
    path: '/app/categories',
    component: CategoriesPage,
  },
  {
    path: '/app/preferences',
    component: PermissionsPage,
  },
  {
    path: '/app/sms-otp',
    component: SmsOtpPage,
  },
  {
    path: '/app/whats-numbers',
    component: WhatsNumbersPage,
  },
  {
    path: '/app/countries',
    component: CountriesPage,
  },
  {
    path: '/app/analytics',
    component: AnalyticsPage,
  },
];

// A.C: Routes before user logged in only
export const preLoginRoutes: RouteProps[] = [
  {
    path: '/login',
    component: LoginPage,
  },
  {
    path: '/validation-code',
    component: ValidationCodePage,
  },
];

// A.C: Routes for both logged in/out
export const publicRoutes: RouteProps[] = [
  {
    path: '/access-denied',
    component: AccessDeniedPage,
  },
  {
    path: '/expired-token',
    component: ExpiredTokenPage,
  },
  {
    path: '/expired-session',
    component: ExpiredSessionPage,
  },
  {
    path: '/404',
    component: NotFoundPage,
  },
];
