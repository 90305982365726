import { Button, getValidatedPageParam, HStack, Stack, Text, VStack } from '@elkaso-app/web-design';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useGetRestaurantsLocationsCountsApi } from '../../../apis/analytics/use-get-restaurants-locations-counts-api';
import { usePageParams } from '../../../hooks/use-page-params';

interface ChartProps {
  title: string;
}

export const RestaurantsLocationsCountsBarChart = ({ title }: ChartProps) => {
  const { data, refetch } = useGetRestaurantsLocationsCountsApi();

  const { setPageParams } = usePageParams();
  const [currentPage, setCurrentPage] = useState(data?.page || null);
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });

  useEffect(() => {
    const labels =
      data?.data?.map((item) => `${item.nameEn} ${getUnicodeFlagIcon(item.countryCode.toUpperCase())}`) || [];
    const branchesData = data?.data?.map((item) => item.branchesCount) || [];
    const warehousesData = data?.data?.map((item) => item.warehousesCount) || [];

    const datasets = [
      {
        type: 'bar',
        label: 'Branches',
        data: branchesData,
        backgroundColor: 'rgba(75, 192, 192, 0.5)',
        borderColor: 'rgba(75, 192, 192, 1)',
      },
      {
        type: 'bar',
        label: 'Warehouses',
        data: warehousesData,
        backgroundColor: 'rgba(153, 102, 255, 0.5)',
        borderColor: 'rgba(153, 102, 255, 1)',
      },
    ];

    setChartData({
      labels,
      datasets,
    } as any);

    setCurrentPage(data?.page || null);
  }, [data]);

  const handleNextPage = () => {
    if (data?.hasNext) {
      setCurrentPage((prevPage) => (prevPage ? prevPage + 1 : null));
      const page = getValidatedPageParam();
      setPageParams({ page: page + 1 });
      refetch();
    }
  };

  const handlePrevPage = () => {
    if (data?.hasPrev) {
      setCurrentPage((prevPage) => (prevPage && prevPage !== 1 ? prevPage - 1 : null));
      const page = getValidatedPageParam();
      setPageParams({ page: page !== 1 ? page - 1 : 1 });
      refetch();
    }
  };

  return (
    <Stack width={'full'} mb={10}>
      <VStack>
        <Bar
          data={chartData}
          options={{
            indexAxis: 'y',
            responsive: true,
            plugins: {
              legend: {
                position: 'top',
              },
              title: {
                position: 'top',
                display: true,
                text: title,
              },
            },
            scales: {
              x: {
                stacked: true,
              },
              y: {
                stacked: true,
                ticks: {
                  font: {
                    size: 16,
                  },
                },
              },
            },
          }}
        />
      </VStack>
      <VStack>
        <HStack maxWidth={'full'} overflow={'hidden'}>
          <Button
            onClick={handlePrevPage}
            variant='solid'
            colorScheme='red'
            mr={2}
            isDisabled={!data?.hasPrev || false}>
            Previous
          </Button>
          <Text>
            {currentPage} ... {data?.pageCount || null}
          </Text>
          <Button onClick={handleNextPage} variant='solid' colorScheme='red' isDisabled={!data?.hasNext || false}>
            Next
          </Button>
        </HStack>
      </VStack>
    </Stack>
  );
};
