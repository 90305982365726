import { BasePagination, formatPrice } from '@elkaso-app/web-design';
import {
  UniqueProductMetadataProps,
  UniqueProductProps,
  useGetUniqueProductsApi,
} from 'apis/products/use-get-unique-products-api';
import { BaseSortButton, BaseTable, BaseTableContainer, SupplierIdFilterButton } from 'components/base-table';
import {
  BaseTableSpinner,
  BaseTableTitle,
  ClearFilterButton,
  CustomTableCell,
  TableCell,
  TableHeader,
} from 'components/base-table/components';
import { useSetPaginationParamsV1 } from 'hooks/use-set-pagination-params-v1';
import React, { useEffect } from 'react';
import { CellProps } from 'react-table';
import { changeTitle } from 'redux/actions';
import { useAppDispatch } from 'redux/hooks';
import { ActionsCell, ProductActionsCell } from '../components/actions-cell';
import { SupplierInfoCell, SupplierInfoCellProps } from '../components/supplier-info-cell';

export const UniqueProductsListScreen = () => {
  const setPaginationParams = useSetPaginationParamsV1();
  const dispatch = useAppDispatch();
  const { isLoading, isFetching, data } = useGetUniqueProductsApi();
  const { pageCount, total, data: productsData } = data ?? {};

  useEffect(() => {
    dispatch(changeTitle('sidebar.products'));
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: <TableHeader name='Supplier' filterButtonComponent={<SupplierIdFilterButton />} />,
        accessor: 'supplier',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['supplier']>) => (
          <CustomTableCell<SupplierInfoCellProps>
            as={SupplierInfoCell}
            supplierId={value.supplierId}
            name={value.name}
          />
        ),
      },
      {
        Header: <TableHeader name='Name' />,
        accessor: 'nameEn',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['nameEn']>) => (
          <TableCell value={value} color='black' fontWeight='normal' />
        ),
      },
      {
        Header: <TableHeader name='Codes' />,
        accessor: 'codes',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['codes']>) => (
          <TableCell value={value?.join(', ') || '---'} fontWeight='semibold' color='gray.600' />
        ),
      },
      {
        Header: <TableHeader name='Prices' />,
        accessor: 'prices',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['prices']>) => (
          <TableCell value={value.map((price) => formatPrice(price))?.join(', ') || '---'} />
        ),
      },
      {
        Header: <TableHeader name='Units' />,
        accessor: 'units',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['units']>) => (
          <TableCell value={value?.join(', ') || '---'} fontWeight='semibold' color='gray.600' />
        ),
      },
      {
        Header: <TableHeader name='Duplications' sortButtonComponent={<BaseSortButton sortBy='duplications' />} />,
        accessor: 'duplications',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['duplications']>) => (
          <TableCell value={value} fontWeight='semibold' color='gray.600' />
        ),
      },
      {
        Header: <TableHeader name='Orders' />,
        accessor: 'ordersCount',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['ordersCount']>) => (
          <TableCell value={value} fontWeight='semibold' color='gray.600' />
        ),
      },
      {
        Header: <TableHeader name='Actions' />,
        accessor: 'actions',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['actions']>) => (
          <CustomTableCell<ProductActionsCell> as={ActionsCell} product={value} />
        ),
      },
    ],
    []
  );

  interface IColumnsAccessorData {
    id: number;
    nameEn: string | null;
    supplier: {
      supplierId: number;
      name: string;
    };
    ids: number[];
    codes: string[];
    units: string[];
    prices: number[];
    duplications: number;
    ordersCount: number;
    actions: UniqueProductProps;
  }

  const columnsAccessorData = React.useMemo(
    () =>
      productsData?.map((item: UniqueProductProps): IColumnsAccessorData => {
        const metadata: UniqueProductMetadataProps[] = item?.metadata || [];

        const ids = [];
        const codes = [];
        const units = [];
        const prices = [];

        for (const item of metadata) {
          ids.push(item.id);
          codes.push(item.code);
          units.push(item.defaultUnit);
          prices.push(item.defaultPrice);
        }

        return {
          id: item?.metadata?.[0]?.id,
          nameEn: item?.nameEn || null,
          supplier: {
            supplierId: item?.supplier?.id,
            name: item?.supplier?.name,
          },
          ids,
          codes: [...new Set(codes)],
          units: [...new Set(units)],
          prices: [...new Set(prices)],
          duplications: item?.duplications,
          ordersCount: item?.ordersCount,
          actions: item,
        };
      }),
    [productsData]
  );

  return (
    <BaseTableContainer isLoading={isLoading}>
      <BaseTableContainer.Header>
        <BaseTableContainer.Header.Left>
          <BaseTableTitle title='sidebar.products' />
          <BaseTableSpinner isFetching={isFetching} />
        </BaseTableContainer.Header.Left>

        <BaseTableContainer.Header.Right>
          <ClearFilterButton />
        </BaseTableContainer.Header.Right>
      </BaseTableContainer.Header>

      <BaseTableContainer.TableWrapper>
        <BaseTable columns={columns} data={columnsAccessorData} />
      </BaseTableContainer.TableWrapper>

      <BaseTableContainer.Footer>
        <BaseTableContainer.Footer.Left />
        <BaseTableContainer.Footer.Right>
          <BasePagination>
            <BasePagination.Pages pagesCount={pageCount} setSearchParams={setPaginationParams} />
            <BasePagination.Limit total={total} setSearchParams={setPaginationParams} />
          </BasePagination>
        </BaseTableContainer.Footer.Right>
      </BaseTableContainer.Footer>
    </BaseTableContainer>
  );
};
