import { HStack } from '@elkaso-app/web-design';
import { UniqueProductProps } from '../../../apis/products/use-get-unique-products-api';

export interface ProductActionsCell {
  product: UniqueProductProps;
}

export const ActionsCell = ({ product }: ProductActionsCell) => {
  return <HStack>{product.nameEn}</HStack>;
};
