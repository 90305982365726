import {
  Button,
  Container,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
  Text,
  VStack,
  WhiteCard,
} from '@elkaso-app/web-design';
import { useCreateSupplierUserApi } from 'apis/supplier/use-create-supplier-user-api';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-number-input';
import { useParams } from 'react-router-dom';
import { changeTitle } from 'redux/actions';
import { useAppDispatch } from 'redux/hooks';

type TInputs = {
  name: string;
  email: string;
  phoneNumber: string;
};

const defaultValues: TInputs = {
  name: '',
  email: '',
  phoneNumber: '',
};

export const AddSupplierUser = () => {
  const dispatch = useAppDispatch();
  const { supplierId } = useParams<{ supplierId: string }>();
  const { isLoading, mutate: createSupplierUserApi } = useCreateSupplierUserApi();

  useEffect(() => {
    dispatch(changeTitle('add'));
  }, []);

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<TInputs>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: { ...defaultValues },
  });

  const onSubmit = (data: TInputs) => {
    const variables = {
      supplierId: supplierId,
      body: {
        name: data.name,
        email: data.email,
        phoneNumber: data.phoneNumber,
      },
    };

    createSupplierUserApi(variables);

    // Reset form data
    reset(defaultValues);
  };

  return (
    <Container maxW='container.md' centerContent>
      <WhiteCard p='lg' w='full'>
        <VStack spacing='lg' align='stretch'>
          <Text color='red.500' fontWeight='500' fontSize='2xl'>
            Add Supplier User
          </Text>

          <Stack as='form' id='addSupplierUser' spacing='lg' onSubmit={handleSubmit(onSubmit)} noValidate>
            <FormControl isRequired isInvalid={errors.name ? true : false}>
              <FormLabel>Name</FormLabel>
              <Input
                placeholder='Please enter your name'
                {...register('name', {
                  required: 'Name is required.',
                })}
              />
              <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isRequired isInvalid={errors.email ? true : false}>
              <FormLabel>E-mail</FormLabel>
              <Input
                type='email'
                placeholder='Please enter your email'
                {...register('email', {
                  required: 'Email is required.',
                  pattern: {
                    value: /^[\w-+.]+@([\w-]+\.)+[\w-]{2,4}$/,
                    message: 'Email address is incorrect.',
                  },
                })}
              />
              <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
            </FormControl>

            <Controller
              control={control}
              name='phoneNumber'
              rules={{
                required: 'Phone number is required.',
                pattern: {
                  value: /^[+][0-9]{12}$/,
                  message: 'Phone number is incorrect.',
                },
              }}
              render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error, invalid } }) => (
                <FormControl isRequired isInvalid={invalid}>
                  <FormLabel>Phone number</FormLabel>

                  <PhoneInput
                    international
                    placeholder='Please enter phone number'
                    defaultCountry='AE'
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    name={name}
                    ref={ref}
                    inputComponent={Input}
                  />

                  <FormErrorMessage>{error?.message}</FormErrorMessage>
                </FormControl>
              )}
            />
          </Stack>

          <Button
            variant='solid'
            colorScheme='red'
            type='submit'
            form='addSupplierUser'
            isLoading={isLoading}
            alignSelf='end'>
            Create User
          </Button>
        </VStack>
      </WhiteCard>
    </Container>
  );
};
