import { HStack, Stack } from '@elkaso-app/web-design';
import { Pie } from 'react-chartjs-2';
import { ProductsCategoriesCountsData } from '../../../apis/analytics/use-get-products-categories-counts-api';

interface ChartProps {
  data: ProductsCategoriesCountsData[];
}

export const ProductsCategoriesCountsPieChart = ({ data }: ChartProps) => {
  const foodAndBeveragePieData = {
    labels: data?.[0]?.dataset?.map((item) => item.l3CategoryTitle) || [],
    datasets: [
      {
        label: 'Count',
        data: data?.[0]?.dataset?.map((item) => item.count) || [],
        borderWidth: 1,
      },
    ],
  };

  const nonFoodPieData = {
    labels: data?.[1]?.dataset?.map((item) => item.l3CategoryTitle) || [],
    datasets: [
      {
        label: 'Count',
        data: data?.[1]?.dataset?.map((item) => item.count) || [],
        borderWidth: 1,
      },
    ],
  };

  return (
    <HStack spacing='sm' align='center' justify='space-evenly'>
      <Stack width={'full'}>
        <Pie
          options={{
            responsive: true,
            plugins: {
              colors: {
                enabled: true,
                forceOverride: true,
              },
              legend: {
                align: 'start',
                position: 'bottom',
                fullSize: false,
              },
              title: {
                align: 'center',
                position: 'top',
                display: true,
                text: `${data?.[0]?.title} L3 Category Stats`,
              },
            },
          }}
          data={foodAndBeveragePieData}
        />
      </Stack>
      <Stack width={'full'}>
        <Pie
          options={{
            responsive: true,
            plugins: {
              colors: {
                enabled: true,
                forceOverride: true,
              },
              legend: {
                align: 'start',
                position: 'bottom',
                fullSize: false,
              },
              title: {
                align: 'center',
                position: 'top',
                display: true,
                text: `${data?.[1]?.title} L3 Category Stats`,
              },
            },
          }}
          data={nonFoodPieData}
        />
      </Stack>
    </HStack>
  );
};
