import { StackProps, Text, VStack } from '@elkaso-app/web-design';
import { Link } from 'react-router-dom';
import { PhoneNumber } from '../../../components/base-table';

export interface ISupplierInfoCell extends StackProps {
  restaurantId: string;
  supplierId: string;
  name: string;
  supplierUserName: string;
  phoneNumber: string;
  isAutoApproved: boolean;
}

export const SupplierInfoCell = ({
  restaurantId,
  name,
  supplierUserName,
  isAutoApproved,
  phoneNumber,
  ...rest
}: ISupplierInfoCell) => {
  return (
    <VStack spacing='sm' align='start' maxW='200px' {...rest}>
      <Link to={`/app/restaurants/warehouse/list/${restaurantId}?limit=45&page=1&name=${name}`}>
        <Text
          whiteSpace='pre-line'
          color={'red.500'}
          fontWeight='500'
          _hover={{ color: 'red.500' }}
          _focus={{ color: 'red.500' }}>
          {name}
        </Text>
      </Link>

      {isAutoApproved && (
        <Text whiteSpace='pre-line' fontSize='sm' color='gray.400'>
          Auto Approved
        </Text>
      )}

      {!isAutoApproved && (
        <Text whiteSpace='pre-line' fontSize='md' color='gray.500'>
          {supplierUserName}
        </Text>
      )}

      {!isAutoApproved && <PhoneNumber value={phoneNumber} />}
    </VStack>
  );
};
