import { useCustomToast } from '@elkaso-app/web-design';
import http from 'apis/config/http-service';
import { useMutationWrapper } from 'apis/config/use-api-wrapper';
import type { AxiosError } from 'axios';
import type { UseMutationOptions, UseMutationResult } from 'react-query';
import { useParams } from 'react-router-dom';
import { useGetSupplierServiceRegionsApi } from './use-get-supplier-service-regions-api';

const endpoint = 'ums/v1/suppliers/:supplierId/regions';
export const createSupplierServiceRegionEndpointIdentifier = endpoint;

interface BodyType {
  name: string;
}

type TData = Record<string, any>;
type TError = AxiosError;

type TVariables = {
  body: BodyType;
};

type Options = Omit<UseMutationOptions<TData, TError, TVariables, unknown>, 'mutationFn'>;
type ReturnType = UseMutationResult<TData, TError, TVariables>;

function constructEndpoint(supplierId: string) {
  return `ums/v1/suppliers/${supplierId}/regions`;
}

export function useCreateSupplierServiceRegionApi(options?: Options): ReturnType {
  const toast = useCustomToast();

  // Get the supplierId from the route parameters
  const { supplierId } = useParams<{ supplierId: string }>();

  if (!supplierId) {
    throw new Error('Supplier ID is missing');
  }

  const fullEndpoint = constructEndpoint(supplierId);

  const { refetch: refetchSupplierServiceRegions } = useGetSupplierServiceRegionsApi(
    supplierId, // Pass the supplierId as the first argument
    {
      enabled: false, // Pass additional options like enabled in the second argument
    }
  );
  const mutationFn = async ({ body }: TVariables) => {
    try {
      const { data } = await http.post(fullEndpoint, body);

      // Refetch supplier items
      refetchSupplierServiceRegions();

      toast({
        description: 'New service region has been created successfully',
        status: 'success',
      });

      return data;
    } catch (error: any) {
      if (!error.response.status || !error.response.data.message) throw error;

      toast({
        description: error.response.data.message,
        status: 'error',
      });

      throw error;
    }
  };

  return useMutationWrapper(mutationFn, { mutationKey: fullEndpoint, ...options });
}
