import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@elkaso-app/web-design';
import { useResendWhatsappApi } from '../../../apis/orders/use-resend-whatsapp-api';

interface IResendEmailModal {
  orderId: number;
  isOpen: boolean;
  onClose: () => void;
}

export const ResendWhatsappModal = ({ orderId, isOpen, onClose }: IResendEmailModal) => {
  const { isLoading, mutate: resendWhatsappApi } = useResendWhatsappApi();

  const onResendWhatsapp = () => {
    const variables = {
      orderId: orderId,
    };

    resendWhatsappApi(variables, {
      onSuccess: () => {
        onClose();
      },
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='md'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Resend Whatsapp Message</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text fontSize='md' color='gray.500'>
            Are you sure you want to resend the whatsapp message?
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button variant='outline' colorScheme='red' me='sm' onClick={onClose}>
            Cancel
          </Button>
          <Button variant='solid' colorScheme='red' onClick={onResendWhatsapp} isLoading={isLoading}>
            Send
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
