import { HStack, StackDivider, VStack, WhiteCard } from '@elkaso-app/web-design';
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import React, { useEffect } from 'react';
import { changeTitle } from 'redux/actions';
import { useAppDispatch } from 'redux/hooks';
import {
  RestaurantsOrdersMonthlyCountsData,
  useGetRestaurantsOrdersMonthlyCountsApi,
} from '../../../apis/analytics/use-get-restaurants-orders-monthly-counts-api';
import { RestaurantsLocationsCountsBarChart } from '../components/restaurants-locations-counts-bar-chart';
import { RestaurantsOrdersMonthlyCountBarChart } from '../components/restaurants-orders-count-bar-chart';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  PointElement,
  LineElement,
  Title,
  LineController,
  Tooltip,
  Legend
);

export const AnalyticsRestaurantDashboardScreen = () => {
  const dispatch = useAppDispatch();
  const { data: restaurantsOrdersMonthlyCounts } = useGetRestaurantsOrdersMonthlyCountsApi();

  useEffect(() => {
    dispatch(changeTitle('sidebar.analytics'));
  }, []);

  interface OrdersChartDataProps {
    restaurantsOrdersData: RestaurantsOrdersMonthlyCountsData[];
  }

  const ordersChartDataProps = React.useMemo((): OrdersChartDataProps => {
    return {
      restaurantsOrdersData: (restaurantsOrdersMonthlyCounts || []) as RestaurantsOrdersMonthlyCountsData[],
    };
  }, [restaurantsOrdersMonthlyCounts]);

  return (
    <HStack maxWidth={'full'} overflow={'hidden'} spacing='md' align='stretch' h='full'>
      <WhiteCard display={'flex'} flexDirection={'column'} width={'full'} overflow={'auto'}>
        <VStack
          padding={3}
          align='normal'
          h='full'
          divider={<StackDivider borderColor='gray.200' p={15} />}
          width={'full'}>
          <HStack justify='space-between'>
            <RestaurantsOrdersMonthlyCountBarChart
              data={ordersChartDataProps.restaurantsOrdersData}
              title={'Restaurants External Orders Monthly Activity stacked by countries'}
            />
          </HStack>
          <HStack justify='space-between'>
            <RestaurantsLocationsCountsBarChart title={'Restaurants Locations'} />
          </HStack>
        </VStack>
      </WhiteCard>
    </HStack>
  );
};
