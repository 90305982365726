import { HStack, VStack, WhiteCard } from '@elkaso-app/web-design';
import {
  MonthlyOrdersPricedData,
  useGetOrdersMonthlyPricedGmvApi,
} from 'apis/analytics/use-get-orders-monthly-priced-gmv-api';
import { ArcElement, BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import React, { useEffect } from 'react';
import { changeTitle } from 'redux/actions';
import { useAppDispatch } from 'redux/hooks';
import {
  MonthlyOrdersNotPricedData,
  useGetOrdersMonthlyNotPricedGmvApi,
} from '../../../apis/analytics/use-get-orders-monthly-not-priced-gmv-api';
import { NotPricedPerCountryOrdersCountBarChart } from '../components/not-priced-per-country-orders-count-bar-chart';
import { PricedPerCountryOrdersCountBarChart } from '../components/priced-per-country-orders-count-bar-chart';
import { PricedPerCountryOrdersGmvBarChart } from '../components/priced-per-country-orders-gmv-bar-chart';

ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement, Title, Tooltip, Legend);

export const AnalyticsOrderDashboardScreen = () => {
  const dispatch = useAppDispatch();
  const { data: orderMonthlyPricedGMVData } = useGetOrdersMonthlyPricedGmvApi();
  const { data: orderMonthlyNotPricedGMVData } = useGetOrdersMonthlyNotPricedGmvApi();

  useEffect(() => {
    dispatch(changeTitle('sidebar.analytics'));
  }, []);

  interface OrdersChartDataProps {
    pricedData: MonthlyOrdersPricedData[];
    notPricedData: MonthlyOrdersNotPricedData[];
  }

  const ordersChartDataProps = React.useMemo((): OrdersChartDataProps => {
    return {
      pricedData: (orderMonthlyPricedGMVData || []) as MonthlyOrdersPricedData[],
      notPricedData: (orderMonthlyNotPricedGMVData || []) as MonthlyOrdersNotPricedData[],
    };
  }, [orderMonthlyPricedGMVData, orderMonthlyNotPricedGMVData]);

  return (
    <HStack maxWidth={'full'} overflow={'hidden'} spacing='md' align='stretch' h='full'>
      <WhiteCard display={'flex'} flexDirection={'column'} width={'full'} overflow={'auto'}>
        <VStack padding={3} align='normal' h='full'>
          <HStack>
            <PricedPerCountryOrdersCountBarChart
              data={ordersChartDataProps.pricedData}
              title={'Priced external orders monthly count stacked by countries'}
            />
            <NotPricedPerCountryOrdersCountBarChart
              data={ordersChartDataProps.notPricedData}
              title={'Not priced external orders monthly count stacked by countries'}
            />
          </HStack>
          <HStack justify='space-between'>
            <PricedPerCountryOrdersGmvBarChart
              data={ordersChartDataProps.pricedData}
              title={'External orders monthly GMV stacked by countries'}
            />
          </HStack>
        </VStack>
      </WhiteCard>
    </HStack>
  );
};
