import { Avatar, Box, Flex, Stack, Text } from '@elkaso-app/web-design';
import { PhoneNumber } from './phone-number';

export interface IUserInfoCell {
  name: string;
  email: string;
  phoneNumber: string;
}

export const AccountInfo = ({ name, email, phoneNumber }: IUserInfoCell) => {
  return (
    <Flex align='center'>
      <Box>
        <Avatar name={name} size='md' bg='red.500' color='white' />
      </Box>

      <Stack spacing='xs' ms='md' color='black'>
        <Text fontWeight='bold' fontSize='md'>
          {name}
        </Text>
        <Text>{email}</Text>
        <PhoneNumber value={phoneNumber} />
      </Stack>
    </Flex>
  );
};
