import { HStack, VStack, WhiteCard } from '@elkaso-app/web-design';
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Colors,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import React, { useEffect } from 'react';
import { changeTitle } from 'redux/actions';
import { useAppDispatch } from 'redux/hooks';
import {
  ProductsCategoriesCountsData,
  useGetProductsCategoriesCountsApi,
} from '../../../apis/analytics/use-get-products-categories-counts-api';
import { ProductsCategoriesCountsPieChart } from '../components/products-categories-counts-pie-chart';

ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement, Title, Tooltip, Legend, Colors);

export const AnalyticsProductDashboardScreen = () => {
  const dispatch = useAppDispatch();
  const { data: productsCategoriesCountsData } = useGetProductsCategoriesCountsApi();

  useEffect(() => {
    dispatch(changeTitle('sidebar.analytics'));
  }, []);

  interface OrdersChartDataProps {
    categoriesCountsData: ProductsCategoriesCountsData[];
  }

  const productsChartProps = React.useMemo((): OrdersChartDataProps => {
    return {
      categoriesCountsData: (productsCategoriesCountsData || []) as ProductsCategoriesCountsData[],
    };
  }, [productsCategoriesCountsData]);

  return (
    <HStack maxWidth={'full'} overflow={'hidden'} spacing='md' align='stretch' h='full'>
      <WhiteCard display={'flex'} flexDirection={'column'} width={'full'} overflow={'auto'}>
        <VStack padding={3} align='normal' h='full'>
          <ProductsCategoriesCountsPieChart data={productsChartProps.categoriesCountsData} />
        </VStack>
      </WhiteCard>
    </HStack>
  );
};
