import { Button, FileUploader, HStack, Text, UploadedFile, VStack } from '@elkaso-app/web-design';
import { DownArrowIcon } from 'pages/restaurants/icons/down-arrow-icon';
import { UpArrowIcon } from 'pages/restaurants/icons/up-arrow-icon';
import { useState } from 'react';
import { useDownloadBenchmarkingCSV } from '../use-download-benchmarking-csv';
import { useUploadBenchmarkingCSV } from '../use-upload-benchmarking-csv';

export const UploadDownloadCSVFile = () => {
  const [file, setFile] = useState<UploadedFile>();
  const { mutate: uploadApi, isLoading } = useUploadBenchmarkingCSV();
  const { refetch: downloadSupplierRankingCSV } = useDownloadBenchmarkingCSV({ enabled: false });

  const onUploadButton = () => {
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file as File);

    const variables = {
      body: formData,
    };

    uploadApi(variables, {
      onSuccess: () => {
        setFile(undefined);
      },
    });
  };

  return (
    <VStack spacing='md' align='start' margin='24px'>
      <HStack display='flex' alignItems='center' justifyContent='space-between' w='full'>
        <Text color='gray.500'>Upload a csv file after editing benchmarking data.</Text>
        <HStack spacing='sm' cursor='pointer' w='fit-content' onClick={() => downloadSupplierRankingCSV()}>
          <Text color='red.500' fontWeight='semibold' userSelect='none'>
            Download Benchmarking Data
          </Text>
          <DownArrowIcon />
        </HStack>
      </HStack>

      <FileUploader
        style='style_01'
        message='Drag "n" drop CSV file here, or click to select file...only CSV file type is allowed.'
        acceptedTypes={['text/csv']}
        multiple={false}
        onRemove={() => {
          setFile(undefined);
        }}
        onRemoveAll={() => {
          setFile(undefined);
        }}
        onUpload={(files) => {
          setFile(files[0]);
        }}
      />

      {!!file && (
        <Button
          colorScheme='red'
          rightIcon={<UpArrowIcon />}
          size='lg'
          isLoading={isLoading}
          alignSelf='end'
          onClick={onUploadButton}
          isDisabled={!file}>
          Upload File
        </Button>
      )}
    </VStack>
  );
};
