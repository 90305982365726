import {
  BoxProps,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
  VStack,
} from '@elkaso-app/web-design';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCreateSupplierServiceRegionApi } from 'apis/supplier/use-create-supplier-service-region-api';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';

type FormValues = {
  name: string;
};

const schema = yup.object().shape({
  name: yup.string().required('Region name is required'),
});

const defaultValues: FormValues = {
  name: '',
};

export interface IAddServiceRegionModalCell extends BoxProps {
  trigger: JSX.Element;
}

export const AddServiceRegionModalCell = ({ trigger }: IAddServiceRegionModalCell) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { isLoading: isAddServiceRegionLoading, mutate: addServiceRegion } = useCreateSupplierServiceRegionApi();

  const {
    register,
    handleSubmit,
    reset,

    formState: { errors },
  } = useForm<FormValues>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues,
  });

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    const variables = {
      body: {
        name: data?.name,
      },
    };

    addServiceRegion(variables, {
      onSuccess: () => {
        onClose();
        reset(defaultValues);
      },
    });
  };

  return (
    <>
      {React.cloneElement(trigger, { onClick: onOpen })}

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        onCloseComplete={() => reset(defaultValues)}
        closeOnOverlayClick={false}
        isCentered={false}
        size='3xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <VStack spacing='xs' align='start'>
              <Heading as='h3' size='lg'>
                Create New Service Region
              </Heading>
              <Text color='gray.500' fontSize='md' fontWeight='normal'>
                {'Make sure to fill the correct service region name before saving your changes'}
              </Text>
            </VStack>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack as='form' id='addServiceRegionForm' spacing='lg' onSubmit={handleSubmit(onSubmit)} noValidate>
              <VStack spacing='md' align='start' justify='start'>
                <FormControl isInvalid={!!errors?.name} isRequired>
                  <FormLabel>Name</FormLabel>
                  <Input variant='outline' placeholder='ex. Central' {...register('name')} />

                  <FormErrorMessage>{errors?.name?.message}</FormErrorMessage>
                </FormControl>
              </VStack>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <HStack spacing='md'>
              <Button variant='ghost' colorScheme='gray' onClick={onClose}>
                Cancel
              </Button>
              <Button
                variant='solid'
                colorScheme='red'
                type='submit'
                form='addServiceRegionForm'
                isLoading={isAddServiceRegionLoading}>
                {'Save'}
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
