import { StackProps, Text, VStack } from '@elkaso-app/web-design';
import { Link } from 'react-router-dom';

export interface SupplierInfoCellProps extends StackProps {
  supplierId: number;
  name: string;
}

export const SupplierInfoCell = ({ name, ...rest }: SupplierInfoCellProps) => {
  return (
    <VStack spacing='sm' align='start' maxW='200px' {...rest}>
      <Link to={`/app/suppliers/list?limit=45&name=${name}`}>
        <Text
          whiteSpace='pre-line'
          color={'red.500'}
          fontWeight='500'
          _hover={{ color: 'red.500' }}
          _focus={{ color: 'red.500' }}>
          {name}
        </Text>
      </Link>
    </VStack>
  );
};
