import { useEffect } from 'react';
import { changeTitle } from 'redux/actions';
import { useAppDispatch } from 'redux/hooks';
import { UploadDownloadCSVFile } from '../components/upload-download-csv-file';

export const CSVDownloadScreen = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(changeTitle('breadcrumb.benchmarking'));
  }, []);
  return (
    <div>
      <UploadDownloadCSVFile></UploadDownloadCSVFile>
    </div>
  );
};
