import { Stack } from '@elkaso-app/web-design';
import { Bar } from 'react-chartjs-2';
import { MonthlyOrdersPricedData } from '../../../apis/analytics/use-get-orders-monthly-priced-gmv-api';
import { MonthlyTransfersPricedData } from '../../../apis/analytics/use-get-transfers-monthly-priced-gmv-api';

const countryColors: Record<string, { backgroundColor: string; borderColor: string }> = {
  SA: { backgroundColor: 'rgba(255, 99, 132, 0.6)', borderColor: 'rgba(255, 99, 132, 1)' }, // Saudi Arabia
  AE: { backgroundColor: 'rgba(54, 162, 235, 0.6)', borderColor: 'rgba(54, 162, 235, 1)' }, // United Arab Emirates
  BE: { backgroundColor: 'rgba(255, 206, 86, 0.6)', borderColor: 'rgba(255, 206, 86, 1)' }, // Belgium
  BH: { backgroundColor: 'rgba(75, 192, 192, 0.6)', borderColor: 'rgba(75, 192, 192, 1)' }, // Bahrain
  OM: { backgroundColor: 'rgba(153, 102, 255, 0.6)', borderColor: 'rgba(153, 102, 255, 1)' }, // Oman
  default: { backgroundColor: 'rgb(200, 191, 20, 0.6)', borderColor: 'rgba(200, 191, 20, 1)' }, // default
};

interface ChartProps {
  data: MonthlyOrdersPricedData[] | MonthlyTransfersPricedData[];
  title: string;
}

export const PricedPerCountryOrdersCountBarChart = ({ data: pricedDataset, title }: ChartProps) => {
  const labels = [
    ...new Set(
      pricedDataset.map((item) => new Date(item.date).toLocaleDateString('en-US', { year: 'numeric', month: 'short' }))
    ),
  ];

  const pricedDataCountries = new Set(pricedDataset.map((item) => item.countryCode.trim().toUpperCase()));
  const countryBasedPricedCountDataset: any[] = [];

  // Create datasets for not priced orders
  for (const countryCode of pricedDataCountries) {
    const countryData = pricedDataset.filter((item) => item.countryCode === countryCode);
    const groupedByLabelData: Record<string, number> = {};

    for (const item of countryData) {
      const key = new Date(item.date).toLocaleDateString('en-US', { year: 'numeric', month: 'short' });
      groupedByLabelData[key] = item.count || 0;
    }

    const data = [];
    for (const label of labels) {
      data.push(groupedByLabelData[label] || 0);
    }

    countryBasedPricedCountDataset.push({
      label: countryCode,
      data,
      backgroundColor: countryColors[countryCode]?.backgroundColor || countryColors['default'].backgroundColor,
      borderColor: countryColors[countryCode]?.borderColor || countryColors['default'].borderColor,
      borderWidth: 1,
      stack: 'priced',
    });
  }

  return (
    <Stack width={'full'}>
      <Bar
        options={{
          responsive: true,
          plugins: {
            legend: {
              position: 'top',
            },
            title: {
              display: true,
              text: title,
            },
          },
          scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true,
              beginAtZero: true,
            },
          },
        }}
        data={{
          labels,
          datasets: countryBasedPricedCountDataset,
        }}
      />
    </Stack>
  );
};
