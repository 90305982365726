import { FullPageSpinner } from '@elkaso-app/web-design';
import { useMeApi } from 'apis/users/use-me-api';
import AssignAdminDialog from 'components/Dialog/AssignAdmin';
import AttachSupplierDialog from 'components/Dialog/AttachSupplierDialog';
import CatalogDialog from 'components/Dialog/CatalogDialog';
import CommentDialog from 'components/Dialog/CommentDialog';
import ConfirmationDialog from 'components/Dialog/ConfirmationDialog';
import ImageCropperDialog from 'components/Dialog/ImageCropperDialog';
import ImageDialog from 'components/Dialog/imageDialog';
import InfoDialog from 'components/Dialog/InfoDialog';
import MergeRestaurantDialog from 'components/Dialog/MergeRestaurantCompDialog';
import MergeSupplierUser from 'components/Dialog/MergeSupplierUserDialog';
import SelectAdminDialog from 'components/Dialog/SelectAdmin';
import SelectAssignMethodDialog from 'components/Dialog/SelectAssignMethod';
import UserLoginDialog from 'components/Dialog/UserLoginDialog';
import ErrorBoundary from 'components/ErrorBoundary';
import { PreLoginRoute } from 'components/pre-login-route';
import { ProtectedRoute } from 'components/protected-route';
import { updateAbility } from 'configs/ability';
import { ThemeProviders } from 'constants/theme-providers';
import { useAutoLogout } from 'hooks/use-auto-logout';
import { useIsLoggedIn } from 'hooks/use-is-logged-in';
import { useSetLangHtmlTags } from 'hooks/use-set-lang-html-tags';
import { useSyncLogout } from 'hooks/use-sync-logout';
import 'lib/reactify-css';
import { useEffect } from 'react';
import 'react-phone-number-input/style.css';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { NotificationContainer } from 'react-notifications';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { getCurrentUser } from 'redux/actions';
import { useAppDispatch } from 'redux/hooks';
import { preLoginRoutes, privateRoutes, publicRoutes } from 'utils/app-routes';

function App() {
  useAutoLogout();
  useSyncLogout();
  useSetLangHtmlTags();

  const location = useLocation();
  const { isLoading, data: me } = useMeApi();
  const dispatch = useAppDispatch();
  const isLoggedIn = useIsLoggedIn();

  // TEMP: Get the old user object and override the current one in localStorage
  useEffect(() => {
    if (!isLoggedIn) return;
    dispatch(getCurrentUser());
  }, []);

  // Update user abilities
  useEffect(() => {
    const abilities = me?.roles?.[0]?.abilities ?? [];
    updateAbility(abilities);
  }, [me]);

  if (isLoading || !preLoginRoutes.length || !publicRoutes.length) return <FullPageSpinner />;

  if (location.pathname === '/') {
    if (!isLoggedIn) return <Redirect to={'/login'} />;
    return <Redirect to={'/app/statistics'} />;
  }

  return (
    <ThemeProviders>
      <>
        <Switch>
          {privateRoutes.map(({ path, component, ...rest }, index) => (
            <ProtectedRoute key={index} path={path} component={component} {...rest} />
          ))}

          {preLoginRoutes.map(({ path, component, ...rest }, index) => (
            <PreLoginRoute key={index} path={path} component={component} {...rest} />
          ))}

          {publicRoutes.map(({ path, component, ...rest }, index) => (
            <Route key={index} path={path} component={component} {...rest} />
          ))}

          <Route path='*' render={() => <Redirect to='/404' />} />
        </Switch>

        {isLoggedIn && (
          <ErrorBoundary>
            <ConfirmationDialog />
            <UserLoginDialog />
            <AttachSupplierDialog />
            <CommentDialog />
            <CatalogDialog />
            <ImageDialog />
            <InfoDialog />
            <ImageCropperDialog />
            <MergeSupplierUser />
            <MergeRestaurantDialog />
            <AssignAdminDialog />
            <SelectAdminDialog />
            <SelectAssignMethodDialog />
          </ErrorBoundary>
        )}

        <NotificationContainer />
      </>
    </ThemeProviders>
  );
}

export default App;
