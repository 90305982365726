import { HStack, VStack, WhiteCard } from '@elkaso-app/web-design';
import { ArcElement, BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import React, { useEffect } from 'react';
import { changeTitle } from 'redux/actions';
import { useAppDispatch } from 'redux/hooks';
import {
  MonthlyTransfersNotPricedData,
  useGetTransfersMonthlyNotPricedGmvApi,
} from '../../../apis/analytics/use-get-transfers-monthly-not-priced-gmv-api';
import {
  MonthlyTransfersPricedData,
  useGetTransfersMonthlyPricedGmvApi,
} from '../../../apis/analytics/use-get-transfers-monthly-priced-gmv-api';
import { NotPricedPerCountryOrdersCountBarChart } from '../components/not-priced-per-country-orders-count-bar-chart';
import { PricedPerCountryOrdersCountBarChart } from '../components/priced-per-country-orders-count-bar-chart';
import { PricedPerCountryOrdersGmvBarChart } from '../components/priced-per-country-orders-gmv-bar-chart';

ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement, Title, Tooltip, Legend);

export const AnalyticsTransferDashboardScreen = () => {
  const dispatch = useAppDispatch();
  const { data: transferMonthlyPricedGMVData } = useGetTransfersMonthlyPricedGmvApi();
  const { data: transferMonthlyNotPricedGMVData } = useGetTransfersMonthlyNotPricedGmvApi();

  useEffect(() => {
    dispatch(changeTitle('sidebar.analytics'));
  }, []);

  interface OrdersChartDataProps {
    pricedData: MonthlyTransfersPricedData[];
    notPricedData: MonthlyTransfersNotPricedData[];
  }

  const ordersChartDataProps = React.useMemo((): OrdersChartDataProps => {
    return {
      pricedData: (transferMonthlyPricedGMVData || []) as MonthlyTransfersPricedData[],
      notPricedData: (transferMonthlyNotPricedGMVData || []) as MonthlyTransfersNotPricedData[],
    };
  }, [transferMonthlyPricedGMVData, transferMonthlyNotPricedGMVData]);

  return (
    <HStack maxWidth={'full'} overflow={'hidden'} spacing='md' align='stretch' h='full'>
      <WhiteCard display={'flex'} flexDirection={'column'} width={'full'} overflow={'auto'}>
        <VStack padding={3} align='normal' h='full'>
          <HStack>
            <PricedPerCountryOrdersCountBarChart
              data={ordersChartDataProps.pricedData}
              title={'Priced internal transfers monthly count stacked by countries'}
            />
            <NotPricedPerCountryOrdersCountBarChart
              data={ordersChartDataProps.notPricedData}
              title={'Not priced internal transfers monthly count stacked by countries'}
            />
          </HStack>
          <HStack justify='space-between'>
            <PricedPerCountryOrdersGmvBarChart
              data={ordersChartDataProps.pricedData}
              title={'Internal transfers monthly GMV stacked by countries'}
            />
          </HStack>
        </VStack>
      </WhiteCard>
    </HStack>
  );
};
