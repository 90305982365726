import { Text, VStack } from '@elkaso-app/web-design';
import { Link } from 'react-router-dom';

export interface UserInfoCellProps {
  name: string;
  email: string;
  phoneNumber: string;
}

export const UserInfoCell = ({ name, email, phoneNumber }: UserInfoCellProps) => {
  return (
    <VStack align='start' spacing='sm' py='sm'>
      <Link to={`/app/elkasoUsers/list?limit=45&page=1&phoneNumber=${encodeURIComponent(phoneNumber)}`}>
        <Text fontWeight='bold' fontSize={'sm'} color={'red.500'} _hover={{ textDecoration: 'underline' }}>
          {name}
        </Text>
      </Link>
      <Text color={'grey'}>{email}</Text>
      <Text color={'grey'}>{phoneNumber}</Text>
    </VStack>
  );
};
