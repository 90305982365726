import { Stack } from '@elkaso-app/web-design';
import { Bar } from 'react-chartjs-2';
import { SuppliersOrdersMonthlyCountsData } from '../../../apis/analytics/use-get-supplier-orders-monthly-counts-api';

const countryColors: Record<string, { backgroundColor: string; borderColor: string }> = {
  SA: { backgroundColor: 'rgba(255, 99, 132, 0.6)', borderColor: 'rgba(255, 99, 132, 1)' }, // Saudi Arabia
  AE: { backgroundColor: 'rgba(54, 162, 235, 0.6)', borderColor: 'rgba(54, 162, 235, 1)' }, // United Arab Emirates
  BE: { backgroundColor: 'rgba(255, 206, 86, 0.6)', borderColor: 'rgba(255, 206, 86, 1)' }, // Belgium
  BH: { backgroundColor: 'rgba(75, 192, 192, 0.6)', borderColor: 'rgba(75, 192, 192, 1)' }, // Bahrain
  OM: { backgroundColor: 'rgba(153, 102, 255, 0.6)', borderColor: 'rgba(153, 102, 255, 1)' }, // Oman
  default: { backgroundColor: 'rgb(200, 191, 20, 0.6)', borderColor: 'rgba(200, 191, 20, 1)' }, // default
};

interface ChartProps {
  data: SuppliersOrdersMonthlyCountsData[];
  title: string;
}

export const SuppliersOrdersMonthlyCountBarChart = ({ data: rawDataset, title }: ChartProps) => {
  const labels = [
    ...new Set(
      rawDataset.map((item) => new Date(item.date).toLocaleDateString('en-US', { year: 'numeric', month: 'short' }))
    ),
  ];

  const rawDataCountries = new Set(rawDataset.map((item) => item.countryCode.trim().toUpperCase()));
  const countryBasedTotalSuppliersDataset: any[] = [];
  const countryBasedActiveSuppliersDataset: any[] = [];

  for (const countryCode of rawDataCountries) {
    const countryData = rawDataset.filter((item) => item.countryCode === countryCode);
    const groupedByLabelTotalSuppliersData: Record<string, number> = {};
    const groupedByLabelActiveSuppliersData: Record<string, number> = {};

    for (const item of countryData) {
      const key = new Date(item.date).toLocaleDateString('en-US', { year: 'numeric', month: 'short' });
      groupedByLabelTotalSuppliersData[key] = item.totalSuppliers || 0;
      groupedByLabelActiveSuppliersData[key] = item.activeSuppliersCount || 0;
    }

    const totalSuppliersData = [];
    const activeSuppliersData = [];
    for (const label of labels) {
      totalSuppliersData.push(groupedByLabelTotalSuppliersData[label] || 0);
      activeSuppliersData.push(groupedByLabelActiveSuppliersData[label] || 0);
    }

    countryBasedTotalSuppliersDataset.push({
      type: 'bar',
      label: countryCode,
      data: totalSuppliersData,
      backgroundColor: countryColors[countryCode]?.backgroundColor || countryColors['default'].backgroundColor,
      borderColor: countryColors[countryCode]?.borderColor || countryColors['default'].borderColor,
      borderWidth: 1,
      stack: 'total',
    });

    countryBasedActiveSuppliersDataset.push({
      type: 'line',
      label: countryCode,
      data: activeSuppliersData,
      borderColor: countryColors[countryCode]?.borderColor || countryColors['default'].borderColor,
      borderWidth: 2,
      fill: false,
      stack: 'active',
    });
  }

  return (
    <Stack width={'full'}>
      <Bar
        options={{
          responsive: true,
          plugins: {
            legend: {
              position: 'top',
            },
            title: {
              display: true,
              text: title,
            },
          },
          scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true,
              beginAtZero: true,
            },
          },
        }}
        data={{
          labels,
          datasets: [...countryBasedTotalSuppliersDataset, ...countryBasedActiveSuppliersDataset],
        }}
      />
    </Stack>
  );
};
