import { StackProps, Text, VStack } from '@elkaso-app/web-design';
import { Link } from 'react-router-dom';
import { PhoneNumber } from '../../../components/base-table';

export interface IRestaurantInfoCell extends StackProps {
  restaurantId: string;
  name: string;
  subEntityName: string;
  phoneNumber: string;
}

export const RestaurantInfoCell = ({
  restaurantId,
  name,
  subEntityName,
  phoneNumber,
  ...rest
}: IRestaurantInfoCell) => {
  return (
    <VStack spacing='sm' align='start' maxW='200px' {...rest}>
      <Link to={`/app/restaurants/details/${restaurantId}`}>
        <Text
          whiteSpace='pre-line'
          color={'red.500'}
          fontWeight='500'
          _hover={{ color: 'red.500' }}
          _focus={{ color: 'red.500' }}>
          {name}
        </Text>
      </Link>

      <Text whiteSpace='pre-line' fontSize='md' color='gray.500'>
        {subEntityName}
      </Text>

      <PhoneNumber value={phoneNumber} />
    </VStack>
  );
};
